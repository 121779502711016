@import 'safari-variables';
@import 'safari-fonts';
@import 'safari-imports';
@import 'safari-table';
@import 'safari-overrides';

/*=====  End of OVERRIDE VARIABLES FROM _variables.scss above  ======*/
/*=============================================
=            `GENERAL        =
=============================================*/
html {
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
:focus {
  outline: none !important;
}

//removes the "grip tape" from the bottom right of the text area that are only found in lpms pages
textarea.s_textarea {
  min-height: 31px;

  @include scroller;
}

/* High Res Display: Start*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    -webkit-font-smoothing: subpixel-antialiased;
  }

  /* High Res Display:  End */
}

body {
  background-color: $paper;
}

ins {
  text-decoration: none;
}

.s_dev {
  color: $purple;
  font-family: 'courier', monospace;
  font-size: 0.9em;
  background: lighten($purple, 30%);
}

@include media-breakpoint-up(xl) {
  .s_container-wide {
    max-width: 1400px;
  }
}

@include media-breakpoint-down(sm) {
  .s_container-wide {
    max-width: 1400px;
  }
}

.s_text-complete {
  color: $sky;
}

.s_no-items {
  color: $gray-600;
}

$bg-array: 404, 403, maint;

@each $bg-id in $bg-array {
  .s_bg-#{nth($bg-id, 1)} {
    background: url('../assets/img/s_bg-#{nth($bg-id, 1)}.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.s_bg-logout {
  background: url('../assets/img/s_bg-404.jpg');
  background-position: center bottom;
  background-size: cover;
}

.s_uppercase {
  text-transform: uppercase;
}

.progress {
  height: 3px;
}

.s_callout {
  background: $tope-lite;
  border-radius: $rds;
}

.s_bg-light-warning {
  background-color: $tope;
}

.s_bg-light-secondary {
  background-color: lighten($secondary, 52%);
}

.s_terms {
  display: flex;
  align-content: flex-end;
  align-items: stretch;

  div {
    margin-left: auto;
  }
}

.s_tnc {
  @include truncate;
}

.s_indent {
  margin-left: 28px;
}

.s_round {
  border-radius: 1em;
}

.s_saveto {
  background: $gray-600;
  font-weight: 100;
  font-size: 10px;
  min-width: 130px;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  margin-top: -1px;
  display: inline-block;
  text-align: center;
  line-height: 16px;
  text-wrap: nowrap;
}

hr {
  border-top: 1px dotted var(--brown-lite);
  margin: 0 0 10px;
}

.s_label-stack {
  font-size: 11px !important;
  line-height: 14px !important;
  text-align: center;
  float: right;
}

.s_label-link {
  font-size: 0.9em;
  line-height: 12px;
  float: right;
  padding: 0;
  cursor: pointer;
}

.s_error {
  display: block;
  white-space: pre-wrap;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: none;
  overflow-wrap: break-word;
  background: rgba(220, 70, 70, 0.15);
  padding: 4px 8px;
  margin-top: 1px;

  &.disabled {
    background: transparent;
    opacity: 1;

    &::placeholder {
      opacity: 1;
      color: $red;
    }
  }
}

// /* Don't display the number up/down arrows on number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn {
  &.btn-primary,
  &.btn-secondary,
  &.btn-success,
  &.btn-danger,
  &.btn-warning,
  &.btn-info,
  &.btn-light,
  &.btn-dark,
  &.btn-outline,
  &.btn-outline-primary,
  &.btn-outline-secondary,
  &.btn-outline-success,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-info,
  &.btn-outline-light,
  &.btn-outline-dark,
  &.btn-ghost,
  &.btn-ghost-primary,
  &.btn-ghost-secondary,
  &.btn-ghost-success,
  &.btn-ghost-danger,
  &.btn-ghost-warning,
  &.btn-ghost-info,
  &.btn-ghost-light,
  &.btn-ghost-dark {
    text-transform: uppercase;
  }
}
/*=====  End of GENERAL  ======*/

/*=============================================
=            TOOLTIP            =
=============================================*/

.s_label-tooltip {
  .s_ico {
    margin-top: -8px;
    float: right;
    color: var(--brown);

    &::before {
      font-size: 1rem;
    }
  }
}

.bs-tooltip-top .tooltip-arrow.arrow::before {
  border-top-color: var(--brown);
}

.bs-tooltip-bottom .tooltip-arrow.arrow::before {
  border-bottom-color: var(--brown);
}

.bs-tooltip-left .tooltip-arrow.arrow::before {
  border-left-color: var(--brown);
}

.bs-tooltip-right .tooltip-arrow.arrow::before {
  border-right-color: var(--brown);
}

.tooltip-inner {
  background-color: var(--brown);
}

/*=====  End of TOOLTIP  ======*/

/*=============================================
=            `TEXT STYLES            =
=============================================*/
$txt-array:
  'xxs' '.6rem',
  'xs' '.7rem',
  'sm' '.8rem',
  'md' '.9rem',
  'lg' '1rem',
  'xl' '1.2rem',
  'xxl' '1.5rem',
  '12' '12px';

@each $txt-id in $txt-array {
  .s_txt-#{nth($txt-id, 1)},
  .s_txt-#{nth($txt-id, 1)}.s_ico:before,
  .s_txt-#{nth($txt-id, 1)}.s_ico:after {
    font-size: #{nth($txt-id, 2)} !important;
  }
}

.s_txt-icon {
  line-height: 32px;
}

.s_txt-tight {
  letter-spacing: -0.5px;
}

.s_txt-pass {
  letter-spacing: 5px;
}

strong {
  font-weight: 600;
}

.s_meta {
  list-style-type: none;
  padding-left: 1rem;
  margin-bottom: 8px;

  li {
    color: $gray-600;
    font-size: 0.8rem;

    strong {
      font-weight: $font-weight-bold;
      min-width: 50px;
      display: inline-block;
      text-transform: uppercase;
    }
  }
}

.s_subtext {
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  text-transform: none;
}

/*=====  End of `TEXT STYLES  ======*/

/*=============================================
=            `PORTAL            =
=============================================*/
.s_ptl {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;

  label,
  .s_twy a {
    color: var(--brown);

    & + hr {
      border-top-color: var(--brown-lite);
    }
  }

  .form-control {
    &:hover,
    &:focus,
    &:active {
      border: 1px $gray-300 solid;
    }

    border: 1px $gray-300 solid;
  }

  .s_req.s_big-label::before {
    top: 24px;
  }
}

.s_ptl-head {
  background-color: rgba(255, 255, 255, 0.4);
}

.s_ptl-main,
.s_callout {
  box-shadow: 8px 8px 8px -5px rgba(0, 0, 0, 0.05);
}

.s_ptl-main {
  padding: 20px;
  margin: 10px 0;
  background: $white;
}

.s_ptl-sub {
  background: rgba(255, 255, 255, 0.5);
  padding: 20px;
  margin: 10px 0;
}

.s_ptl-head {
  box-shadow: inset 0px -10px 15px -15px rgba(0, 0, 0, 0.3);
  padding: 16px;
}

.s_ptl-wrap {
  border: 1px solid $gray-300;
  padding: 10px;
}

.s_ptl-status label {
  font-size: 1.1em;
}

/*=====  End of PICKUP PORTAL  ======*/

/*=============================================
=            `DISPLAY            =
=============================================*/
@include media-breakpoint-up(sm) {
  .s_hid-xs {
    display: block;
  }

  .table .s_hid-xs {
    display: table-cell;
  }
}

@include media-breakpoint-down(xs) {
  .s_hid-xs {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .s_hid-sm {
    display: block;
  }

  .table .s_hid-sm {
    display: table-cell;
  }
}

@include media-breakpoint-down(sm) {
  .s_hid-sm {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .s_hid-md {
    display: block;
  }

  .table .s_hid-md {
    display: table-cell;
  }
}

@include media-breakpoint-down(md) {
  .s_hid-md {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .s_hid-lg {
    display: block;
  }

  .table .s_hid-lg {
    display: table-cell;
  }
}

@include media-breakpoint-down(lg) {
  .s_hid-lg {
    display: none;
  }
}

/*=====  End of DISPLAY  ======*/

/*=============================================
=            `HYPERLINK            =
=============================================*/
[disabled],
.disabled,
.form-control:disabled,
.form-control[readonly],
button:disabled,
button[readonly] {
  /* Turns off hyperlink */
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: rgba(255, 255, 255, 0.6);
}

/*=====  End of HYPERLINKS  ======*/

/*=============================================
=            `BACKGROUND STYLES            =
=============================================*/

.s_bg-100 {
  background: lighten($tope, 5%);
}

.s_bg-200 {
  background: $gray-100;
}

.s_bg-warning {
  background: lighten($orange, 45%);
}

.s_bg-danger {
  background: transparentize($color: $red, $amount: 0.85);
}

/*=====  End of `BACKGROUND STYLES  ======*/

/*=============================================
=            NAVBAR            =
=============================================*/

.navbar {
  min-height: 60px;
}

@include media-breakpoint-down(xs) {
  .navbar > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Used to have invisible components trigger dismissal of loading screen */
.navbar--hidden.placeholder {
  display: none;
}

/*----------  NOTIFICATION DROPDOWN  ----------*/
#s_notification {
  .dropdown-toggle.active ins {
    color: $yellow;
    font-weight: 600;

    &::before {
      color: $yellow;
    }
  }

  .s_ico-embed {
    color: var(--brown-mid);

    ins {
      margin-right: 0;
      margin-top: -2px;

      &::before {
        color: var(--brown-mid);
      }
    }
  }

  &.show,
  &:hover,
  &:active {
    .dropdown-toggle.active ins {
      color: $white;

      &::before {
        color: $yellow;
      }
    }
  }

  #s_notification-content {
    margin-right: -63px;
    width: 375px;

    &::before {
      left: 288px;
    }

    .dropdown-item {
      .s_ico {
        margin-right: -2px;
        float: right;

        &::before {
          font-size: 20px;
          line-height: 32px;
        }
      }

      .s_ntf-reason {
        font-size: 0.7rem;
      }

      .s_ntf-subject {
        @include truncate;
        max-width: 312px;
      }
    }
  }
}

/*----------  HELP DROPDOWN  ----------*/
#s_help {
  .s_encircle {
    border-radius: 4px !important;

    ins {
      margin-right: 0;
      margin-top: -2px;
    }
  }

  #s_notification-content {
    width: 300px;
    margin-right: -110px;

    &::after {
      left: 165px;
    }
  }
}

.s_notification-reason {
  font-size: 12px;
  font-weight: 100;
}

/*----------  ACCOUNT DROPDOWN  ----------*/

.s_encircle {
  width: 28px;
  height: 28px;
  font-size: 13px;
  line-height: 26px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  border: 1px var(--brown) solid;
  border-radius: 50%;
  margin: 0 0 0 20px;
  color: var(--brown-mid);
}

.s_lpo-header {
  #s_help {
    .s_encircle {
      border-color: $gold;
      color: $gold;
    }

    &.show .s_encircle,
    a:hover .s_encircle {
      background: $gold;
      color: var(--brown-drk);
    }
  }

  .s_response-type-label {
    color: $gold;
    margin-left: 10px;
    font-weight: normal;
  }
}

#s_help.show .s_encircle,
#s_account.show .s_encircle,
.s_encircle:hover {
  color: $white;
  background: var(--brown);
}

.s_account {
  background: var(--brown);
  width: 275px;
  border: 1px transparent solid;
  margin-top: -1px;
  padding: 0;
  top: 45px !important;
  border-radius: 0 0 5px 5px;
}

.s_account::after {
  position: absolute;
  top: -10px;
  left: 250px;
  right: auto;
  display: inline-block !important;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--brown);
  border-left: 10px solid transparent;
  content: '';
}

.s_account .dropdown-item,
.s_new .dropdown-item {
  color: $gray-100;
  padding: 14px 25px;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.s_dropdown-header {
  border-bottom: 1px rgba(255, 255, 255, 0.6) solid;
  line-height: 15px;
  padding-bottom: 15px;
  margin: 0 15px;
  padding: 10px 10px 8px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.7);
}

.s_new .dropdown-item label {
  margin-bottom: 0;
}

.s_break {
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
}

#account {
  #s_account-header {
    border-bottom: 1px rgba(255, 255, 255, 0.6) solid;
    line-height: 15px;
    padding-bottom: 15px;
    margin: 0 15px;
    padding: 10px 10px 8px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.7);
  }
}

#s_account-email {
  font-weight: 100;
  font-size: 0.8rem;
}

.s_matter-search {
  .s_matter-search--link .s_encircle {
    border-radius: 4px !important;

    ins::before {
      font-size: 14px;
    }
  }

  .s_search-clear::after {
    line-height: 29px;
  }

  .filter-option {
    padding: 2px 6px;
    cursor: pointer;
    background-color: transparent;
    // border-radius: 5px;
    margin: 0 6px;
    color: var(--brown);
    font-size: 0.7rem;
    font-weight: normal;
  }

  .filter-option {
    &:hover,
    &.filter-selected {
      color: $white;
      background-color: var(--brown);
    }

    span.filter-option--remove {
      color: #ff8080;
      font-weight: 600;
      margin-left: 4px;
      font-size: smaller;
    }
  }
}

#s_hlp-link,
#s_hlp-lpo {
  span:after {
    font-size: 16px;
    content: '?';
  }
}

#s_newlink {
  margin: 0 10px 0 0;
  border-width: 0;
  background-color: $green;
  height: 28px;
  width: 75px;
  display: inline-block;
  border-radius: 1em;
  text-align: center;
  vertical-align: top;
}

#s_newlink:after {
  font-size: 18px;
  content: '+ NEW';
  color: $white;
  display: block;
  border: none;
}

#s_recipient-email .input-group {
  margin-top: 4px;
}

/*=====  End of NAVBAR  ======*/
/*=============================================
=            `NEW FLYOUT            =
=============================================*/
.dropdown-menu.s_new {
  margin-top: -1px;
  top: 45px;
}

.btn-group .dropdown-menu li {
  padding: 8px;
}

.dropdown-menu.s_new,
.s_dropdown-submenu > .dropdown-menu {
  background: $green;
  min-width: 200px;
  border: 1px solid transparent;
  margin-top: -1px;
  padding: 0;
  border-radius: 0 0 3px 3px;
  color: $white;

  .s_dropdown-submenu {
    position: relative;

    &.pull-left {
      float: none;

      & > .dropdown-menu {
        left: -100%;
        margin-left: 10px;
      }
    }

    & > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -1px;
      margin-left: -1px;
      background: darken($green, 3.5%);
    }

    &:hover > .dropdown-menu {
      display: block;
    }

    & > .dropdown-item::after {
      float: right;
      width: 0;
      height: 0;
      font-family: 'safdings';
      content: '\e107';
      font-weight: 800;
      transform: rotate(-90deg);
      margin-top: 16px;
    }
  }
}

.s_th-link {
  div {
    padding: 0 15px 0 0;
  }
}

.dropdown-menu.s_th {
  margin-top: -2px;
  padding: 0;
  border-radius: 0;
  min-width: 100px;

  .dropdown-item {
    padding: 15px 25px 15px 8px;
    font-weight: 600;
    font-size: 0.8em;

    &:hover {
      cursor: pointer;
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .s_dropdown-submenu {
    position: relative;

    & > .dropdown-menu {
      min-width: 50px;
      border-radius: 0;

      .dropdown-item {
        padding: 15px 8px;
      }
    }

    &.pull-left {
      float: none;

      & > .dropdown-menu {
        left: -100%;
        margin-left: 10px;
      }
    }

    & > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -1px;
      background: $white;
      border: 1px $gray-400 solid;
      border-width: 1px 1px 1px 0;
    }

    &:hover > .dropdown-menu {
      display: block;
    }

    & > .dropdown-item::after {
      float: right;
      width: 0;
      height: 0;
      font-family: 'safdings';
      content: '\e107';
      font-weight: 800;
      transform: rotate(-90deg);
      margin-top: 12px;
    }
  }
}

/*----------  On Hover  ----------*/

.s_newhover {
  display: inline-block;
}

.s_new {
  display: none;
  position: absolute;
}

.s_newhover:hover .s_new {
  display: block;
}

/*----------  TEST  ----------*/
/* Dropdown Button */
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.s_drophover {
  position: relative;
  display: inline-block;
}

/* Links inside the dropdown */
.s_drophover-content {
  display: none;
  position: absolute;
  font-size: 0.9em;
  white-space: nowrap;
  z-index: 1;
  margin-top: -20px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 150px;

  .s_drophover-head {
    border-radius: 5px 5px 0 0;
    border: 1px solid var(--brown);
    border-width: 1px 1px 0 1px;
    font-weight: 500;
    background-color: #f4f4f4;
    font-size: 10px;
    padding: 6px 16px 0;

    hr {
      margin: 4px -8px 0;
      border-top: 1px solid var(--brown);
    }

    &::before {
      position: absolute;
      top: -10px;
      left: 10px;
      display: inline-block !important;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #64554f;
      border-left: 10px solid transparent;
      content: '';
    }

    &::after {
      position: absolute;
      top: -9px;
      left: 10px;
      display: inline-block !important;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #f4f4f4;
      border-left: 10px solid transparent;
      content: '';
    }
  }

  .s_drophover-item {
    color: var(--primary);
    padding: 16px;
    text-decoration: none;
    display: block;
    font-size: 11px;
    border: 1px solid var(--brown);
    border-width: 0 1px;
    font-weight: 500;
    background-color: #f4f4f4;

    &:hover {
      background-color: var(--brown-lite);
      color: var(--brown);
    }

    &:last-of-type {
      border-radius: 0 0 5px 5px;
      border-bottom-width: 1px;
    }
  }
}

/* Show the dropdown menu on hover */
.s_drophover:hover .s_drophover-content {
  display: block;
}

/*=====  End of ACTION FLYOUT  ======*/

/*=============================================
=            FOLDER ACTIONS            =
=============================================*/

.s_zebra {
  background: #edecec;
}

.s_hider {
  opacity: 0;
}

.s_highlight:hover {
  background-color: #e6e9ef;

  .s_hider {
    opacity: 1;
  }
}

/*=====  End of FOLDER ACTIONS  ======*/
/*=============================================
=            `RESIZE COLUMN            =
=============================================*/
.s_rsz {
  section {
    padding: 0;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
  }

  .s_sec,
  .s_twy,
  .s_sec-body {
    margin-left: 4px;
    margin-right: 4px;
  }

  section.s_border-bot {
    border-bottom: 1px var(--brown) solid;
  }
}

.s_rsz .s_acc-title {
  margin-left: 15px;
}

.s_rsz .col.embed-responsive {
  min-height: calc(100vh - 100px);
}

@include media-breakpoint-up(sm) {
  /* horizontal panel*/
  .s_rsz {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    /* avoid browser level touch actions */
    touch-action: none;

    .s_rsz-left,
    .s_rsz-right {
      height: calc(100vh - 50px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 14px;
        height: 18px;
      }

      &::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.15);
        -webkit-box-shadow:
          inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }

      &::-webkit-scrollbar-thumb:vertical:hover {
        background-color: var(--brown);
        transition: ease-in-out;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    }
  }

  .s_rsz-split {
    z-index: 1000;
    flex: 0 0 auto;
    width: 15px;
    min-height: 200px;
    cursor: e-size;
    background: #b0a99b;
    margin-top: -10px;
    margin-bottom: -10px;
    box-shadow:
      inset -5px 0 5px #d7d2cc,
      -5px 0 10px #d7d2cc,
      inset 5px 0 5px #d7d2cc,
      5px 0 10px #d7d2cc;

    &:hover,
    &:active {
      background: url(../assets/img/s_rsz-handle.svg) center 40vh no-repeat #b0a99b;
    }
  }

  .s_rsz-right {
    flex: 1 1 auto;
    /* resizable */
    width: 100%;
    min-height: 200px;
    min-width: 200px;
    padding: 0 6px;
  }

  /* full height minus margin in modal */
  .s_rsz-left {
    background-color: $paper;
    flex: 0 0 auto;
    /* only manually resize */
    width: calc(30% - 25px);
    min-width: 50px;
  }

  @include media-breakpoint-down(lg) {
    .s_rsz-left {
      width: 40%;
    }
  }

  @include media-breakpoint-up(xl) {
    .s_rsz-left {
      width: calc(34% - 25px);
    }
  }
}

/*=====  End of RESIZE COLUMNS  ======*/

/*=============================================
=            `AUDIT HISTORY            =
=============================================*/
.s_aud {
  padding: 5px;

  &:nth-child(even) {
    background: rgba(100, 85, 79, 0.06);
  }

  .s_aud-user {
    font-weight: 600;
  }

  .s_aud-date,
  .s_aud-user {
    font-size: 0.8rem;
    color: lighten($brown, 20%); // REVISIT - This particular variable won't be themed
  }

  .s_aud-desc {
    font-size: 0.8rem;
    font-weight: 500;
    color: $black;
  }
}

#section_history-content,
#section_access-content,
#s_content-deliverydetails {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  visibility: hidden;

  &:hover,
  &:focus {
    visibility: visible;
  }

  &::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }

  &::-webkit-scrollbar-thumb:vertical:hover {
    background-color: var(--brown);
    transition: ease-in-out;
  }

  &::-webkit-scrollbar-thumb {
    // this height works as a min-height for the scrollbar
    // setting it smaller may make the scrollbar not appear for large scroll areas
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow:
      inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.s_lpo .s_sec-content {
  visibility: visible;
}

.s_sec-subsec:first-of-type {
  margin-top: -8px;
}

.s_sec-hr {
  border-top: 1px solid var(--brown);
  margin: -1px 4px 0px;
}

/*=====  End of `AUDIT HISTORY  ======*/

/*=============================================
=  `LPO = Legal Process Object (full-screen page "Dialog")   =
=============================================*/
@media (min-width: 576px) {
  .s_lpo-wrap {
    overflow-y: hidden;
  }
}

.s_lpo {
  max-width: 100%;
  margin: 0;
  border: none;

  .s_mb-sm {
    .form-control {
      height: calc(1.68125rem + 2px);
      padding: 0.25rem 0.5rem;
      font-size: 0.7875rem;
      line-height: 1.5;
      border-radius: 0;
    }

    [class*='col-'] {
      margin-bottom: 1px;

      &.s_req::before,
      &.s_sft::before {
        top: 0;
        height: 28px;
      }
    }
  }

  .s_sec-wrap {
    padding: 8px 4px 0;
  }

  /*=============================================
  =            `FILE LISTING                   =
  =============================================*/
  .s_file-wrap {
    min-height: calc(100vh - 132px);
    display: inline-block;
    width: 100%;
    min-width: 800px;
    @include scroller-bot;

    &.s_file-dragdrop-hint {
      background-image: url(../assets/img/bg-dragdrop.webp);
      background-size: 200px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .s_file-wrap-section {
      background-color: $paper;
    }

    .s_filesize {
      font-family: monospace;
      font-size: 0.8rem;
      white-space: pre;

      .s_updated-date {
        display: inline-block;
        width: 72px;
      }
    }

    padding: 8px 4px;

    .datatable-header {
      padding-bottom: 4px;
    }

    .s_top {
      padding-top: 4px;
      padding-bottom: 1px;
      line-height: 20px;

      > .link-button {
        width: 100%;
      }

      .s_file-details {
        display: flex;
        justify-content: left;

        .s_file-name {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .s_attachment-count {
        }
      }

      .s_correspondence-status {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .custom-control.custom-checkbox {
      margin: 0px;

      .custom-control-label.checkbox-inline::before {
        top: -1px;
      }

      &.custom-checkbox-sm {
        .custom-control-label.checkbox-inline::before {
          top: -4px;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
          margin-top: 1px;
        }
      }

      @include media-breakpoint-down(sm) {
        opacity: 1;
      }
    }

    /* This is speficially for the ellipsis menu for the file tables */
    .datatable-body-cell-label {
      height: 100%;
    }

    // Subfolder refers to the subfolders within the file listing.
    .s_subfolder {
      line-height: 32px;
      font-size: 11px;
      display: inline-block;
      margin-right: 14px;
      padding: 0px 0 4px;
      .s_subfolder-count {
        min-width: 32px;
        text-align: center;
        padding-top: 1px;
      }
      .s_saveto {
        line-height: 1.25; // Reverse the line height from above.
      }
    }

    .s_subfolder::before {
      margin-right: -32px;
      font: 100 32px/32px 'safdings';
      float: left;
      margin-top: 1px;
    }

    .s_subfolder {
      color: var(--brown);
      .s_subfolder-label,
      &::before,
      &:hover .s_subfolder-label {
        color: var(--brown);
      }
    }

    .s_subfolder-label {
      margin-left: 12px;
      font-size: 0.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .s_subfolder {
      &.s_active,
      &:hover {
        color: white;
      }
    }

    // This is the folder navigation breadcrumb within the file listings
    .s_breadcrumb-wrapper {
      padding: 4px 0 6px;
      background-color: $tan;

      & > div {
        border-bottom: 1px dotted var(--brown-lite);
        border-top: 1px dotted var(--brown-lite);
        padding: 8px 2px 8px;
      }

      .s_breadcrumb {
        label {
          font-size: 0.7rem;
          margin: 0 8px 0 4px;
        }

        span {
          text-transform: uppercase;

          &.linked:hover {
            background-color: $tan;
            cursor: pointer;
            text-decoration: underline;
          }

          &.leaf {
            font-weight: 600;
          }
        }

        ins::before {
          display: inline-block;
          content: '\e111';
          font-family: 'safdings';
          font-size: 8px;
          margin: 0 10px;
          transform: rotate(-90deg);
          translate: 0 -2px;
        }
      }
    }
  }

  /*=====  End of `FILE LISTING  ======*/

  /*=============================================
 =            `DELIVERY SETUP                 =
 =============================================*/

  .s_delivery-setup {
    .s_step-label {
      font-size: 0.9rem;
    }
  }

  /*=====  End of `DELIVERY SETUP  ======*/

  .s_prefix {
    line-height: 28px;
    padding: 0;
    min-width: 80px;

    &.s_participant {
      padding-right: 17px;
    }
  }

  /*---------- LPO - Header  ----------*/
  .s_lpo-header {
    padding: 0;
    border: none;
    background: url(../assets/img/s_bg-dialog.jpg) var(--brown);
    background-position: center bottom;
    display: block;
    min-height: 50px;

    .s_ico-embed:hover,
    .s_ico-embed:not(.collapsed) {
      .s_fld-title {
        font-weight: bold;
      }

      [class*='s_ico-'] {
        color: var(--brown-drk);

        &::before {
          color: $gold;
        }
      }
    }
  }

  .s_lpo-title {
    font-weight: 600;
  }

  .s_header-gray {
    color: darken($gray-400, 5%);
  }

  .s_pipe {
    border-left: none;
    margin: 2px 16px;
    height: 46px;
  }

  .col-auto.ml-auto {
    padding: 0;
  }

  .s_lpo-hr {
    margin: 0;
    border-color: transparent;
  }

  /*---------- LPO - Header - Meta  ----------*/
  .s_lpo-meta-wrap {
    padding: 5px;
    align-items: center;
    margin-right: 0;
    margin-left: 0;
    color: $gray-200;

    .col-auto:first-of-type {
      padding-left: 6px;
    }

    .col-auto:last-of-type {
      padding-right: 8px;
      padding-top: 4px;
    }

    .s_lpo-meta {
      border-right: 1px $gold solid;

      .s_ico-globe {
        cursor: default;
      }
    }

    .s_lpo-tab-link {
      color: $gold;
    }

    .s_meta-tnc {
      @include truncate;
      max-width: 200px;
      font-size: 0.8rem;
      display: block;
    }
  }

  /*---------- LPO - Folders  ----------*/
  .s_fld {
    margin: 0 4px;
    padding: 4px 0px 4px;
    border-bottom: 1px var(--brown) solid;

    .s_fld-link {
      &:first-of-type {
        // Prevents "shuffling" of other folders when folder label goes bold
        min-width: 44px;
        margin-right: 30px;
      }

      margin: 0;
      display: inline-block;
      border-radius: 0;
      background: transparent;
      transition: background 0.15s ease;
      font-size: 0.7rem;
      min-width: 110px;
      color: var(--brown)-mid;
    }

    .s_folder {
      min-height: 34px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 3px;
      margin-bottom: 3px;
      display: block;
      float: none;
      width: 32px;

      &::before {
        font-size: 32px;
      }

      div {
        padding-top: 2px;
      }
    }

    .s_fld-title {
      line-height: 21px;
      text-align: center;
      font-size: 0.7rem;
    }

    .s_ico-embed {
      &:not(.collapsed) {
        .s_fld-title {
          font-weight: bold;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .s_fld .s_fld-link {
      width: auto;
    }
  }

  .s_fld-viewer {
    border-bottom: 1px solid var(--brown);
    padding: 10px 0;

    .input-group-text {
      background-color: transparent;
      line-height: 32px;
    }

    .s_ico {
      color: var(--brown);

      [class*='s_ico-']::before {
        font-size: 32px;
        display: block;
      }
    }
  }

  .s_ico-embed ins {
    margin: 0;
    width: 28px;

    &::before {
      margin-right: -2rem;
    }
  }

  .s_ico-embed .s_ico-file_fill {
    margin-right: 0px;
  }

  .s_fld-hr {
    hr {
      border-top: 1px solid lighten($gray-600, 10%);
      margin: 0 6px 4px;
    }
  }

  .s_fld-list,
  .s_template-wrap {
    &.collapsing {
      transition: flex 0.3s ease-out;
    }

    .s_req::before,
    .s_req::after {
      top: 0;
      height: 28px;
    }
  }

  .s_fld_nav {
    color: black;
  }

  .s_fld_nav:hover {
    text-decoration: underline;
  }

  /*----------  LPO - Dialog  ----------*/

  .s_filemanage {
    float: right;
    border: 1px solid $link-color;
    border-radius: 3px;
    padding: 0 4px;
    font-size: 0.7rem;
    height: 28px;
    line-height: 28px;
    margin-top: 4px;

    &:hover {
      background: $link-hover-color;
      color: $white;
      border-color: $link-hover-color;
    }
  }

  /*----------  LPO - Action Buttons (e.g. Extend Portal 14 days  ----------*/
  .s_sta-btn {
    .form-group {
      margin-top: 8px;
      margin-bottom: 4px;

      .btn {
        line-height: 0.9rem;
        margin-bottom: 6px;
        padding: 8px;
        align-items: center;
        justify-content: center;
        float: left;

        &:hover span {
          color: $white !important;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  /*---------- LPO - ALERT (e.g. fax delivery failure  ----------*/
  .s_alr {
    font-size: 0.8rem;
    margin: 4px 8px;
    @include truncate;

    div,
    span {
      display: inline-block;
      min-width: 62px;
      font-weight: 600;
      color: var(--label);
      padding: 0;
      margin-right: 14px;
      color: $danger;

      ins {
        float: right;
        margin-left: 4px;

        &::before {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }

  .s_alr-inline {
    ins::before {
      font-size: 12px;
      line-height: 12px;
      color: $danger;
    }
  }

  /*---------- LPO - COMPLIANCE WARNING (e.g. Invalid Jurisdiction ----------*/
  .s_compwarn-warning {
    font-size: 0.8rem;
    float: left;
    @include truncate;

    div,
    span {
      display: inline-block;
      min-width: 62px;
      font-weight: 600;
      color: var(--label);
      padding: 0;
      margin-right: 4px;
      color: $danger;

      ins {
        float: right;
        margin-left: 4px;

        &::before {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }

  .s_compwarn-warning.disabled div {
    color: $gray-600;
  }

  .s_compwarn-title {
    font-size: 0.8rem;
    padding-bottom: 5px;
  }

  .s_compwarn-resolution {
    margin-top: 5px;
  }

  /*---------- LPO STATUS ROW  ----------*/
  .input-group .input-group-append .input-group-btn .btn {
    border-radius: 0 0.2rem 0.2rem 0;
    font-size: 0.9em;
  }

  .s_sta-wrap {
    margin: 0 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--brown);

    .s_sta {
      background: transparent;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      @include truncate;
      display: flex;
      align-items: center;
      line-height: 12px;
    }

    .input-group .input-group-append .btn {
      border-radius: 0.2rem;
    }

    .dropdown-toggle {
      padding: 0 6px;
      font-size: 0.7rem;
    }

    .dropdown-menu {
      padding: 0;
      border-color: var(--brown-drk);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

      .dropdown-item,
      .s_dropdown-header {
        border-bottom: 1px solid $tope-lite;
        padding: 0 4px 0 16px;
        font-size: 12px;
        line-height: 24px;
        font-weight: 600;
        color: $gray-700;
      }

      .s_dropdown-header {
        color: $white;
        padding-left: 8px;
        margin: 0;
        font-size: 0.7rem;
      }
    }
  }

  .s_delivery-portal-acknowledgment {
    div.ck.ck-editor {
      /* Hide the toolbar for document disclosure acknowledgment */
      & > div.ck.ck-editor__top {
        display: none;
      }

      // Tightening the last piece of the margins - CKEditor does some calculation here
      // that makes the margin a bit taller than this, but Tom preferred it to be a bit
      // tighter than CK Editor gives us.
      .ck.ck-editor__editable_inline > :last-child {
        margin-bottom: 4px !important;
      }

      /* Blend the editor into the background so it looks like a plain render */
      div.ck.ck-content {
        background: transparent;
        padding-bottom: 0px !important;

        border: none; // Per #9728
        padding: 8px;
      }
    }

    .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
      box-shadow: none !important;
    }
  }

  .auto-answer-section {
    ins.s_ico::before {
      font-size: 16px;
    }
    .auto-answer-details {
      line-height: 32px;
    }
  }
}

/* EXPERIMENTAL: Making disabled inputs look like labels */
.s_transfer-funds-modal,
.s_garnishment-financials {
  [disabled],
  .disabled,
  .form-control:disabled,
  .form-control[readonly],
  button:disabled,
  button[readonly],
  .read-only {
    background-color: transparent !important;
  }

  .s_right-spacer {
    margin-right: 30px !important;
  }
}

/*=============================================
  =            FLYOUT ACTIONS            =
  =============================================*/
.s_fld-action.btn-group {
  .dropdown-menu {
    padding: 0;
    margin-top: 0;
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.2);

    .dropdown-submenu {
      position: relative;
    }

    .dropdown-submenu > .dropdown-menu {
      top: -50px;
      left: calc(-100% - 2px);
      margin-top: 0px;
      margin-left: 0px;
    }

    .dropdown-submenu:hover > .dropdown-menu {
      display: block;
    }

    li {
      padding: 0;

      &.dropdown-divider {
        padding: 0 8px;
        margin: 0;
      }

      .dropdown-item {
        padding: 8px;
      }
    }
  }
}

/*=====  End of FLYOUT ACTIONS  ======*/
/*=====  End of LPO MODAL DIALOG  ======*/

/*=============================================
=            `STATUS SELECTOR            =
=============================================*/
/*---------- STATUS DROPDOWN & BUTTON  ----------*/
.s_sta-change {
  line-height: 24px;
}

.s_sta-menu {
  margin-right: 0;
}

/*---------- STATUS COLOR CODING  ----------*/
$stacolor-array:
  pre #3e8994 #cddddf primary #0f798a,
  leg #ab5649 #ebd4d1 danger #9b483b,
  del #bc9f6e lighten(#bc9f6e, 25%) warning #a8864d,
  por #4b909b lighten(#335f4c, 60%) success #1d8759,
  clo #7e848b lighten(#797f86, 35%) secondary #797f86;

@each $stacolor-id in $stacolor-array {
  /*---------- CHANGE STATUS DROPDOWN ITEMS  ----------*/
  .dropdown-menu {
    .s_sta-sec-#{nth($stacolor-id, 1)} .s_dropdown-header {
      background: #{nth($stacolor-id, 2)};
      cursor: default;
    }

    .s_sta-sec-#{nth($stacolor-id, 1)} .dropdown-item.active,
    .s_sta-sec-#{nth($stacolor-id, 1)} .dropdown-item:hover {
      background-color: #{nth($stacolor-id, 3)};
      color: $black;
    }

    .s_sta-sec-#{nth($stacolor-id, 1)} .dropdown-item.active {
      cursor: default;
    }

    ins {
      font-weight: 500;
    }
  }

  .s_sta-btn .text-#{nth($stacolor-id, 4)} {
    color: #{nth($stacolor-id, 5)} !important;
  }

  /*---------- SHOW STATUS INLINE  ----------*/
  .s_sta-#{nth($stacolor-id, 1)} {
    .s_sta-main {
      color: #{nth($stacolor-id, 5)};
      font-weight: 600 !important;
    }

    ins {
      font-weight: 500;
    }

    .s_sta-sub {
      color: $gray-500;
      font-size: 0.7rem;
    }
  }

  table .s_sta-#{nth($stacolor-id, 1)} {
    font-size: 12px;

    .s_sta-main {
      font-weight: 600;
    }
  }
}

/*=====  End of `STATUS SELECTOR  ======*/

/*=============================================
=            TABLE TOPPER           =
=============================================*/

.s_tbl-nogap {
  margin-top: -15px;
}

.s_tbl-topper {
  padding: 0 8px;
  //border-top: 1px solid var(--brown-lite);
  margin-right: 0;
  margin-left: 0;
  background-color: $tan;

  .form-control {
    background-color: transparent;
    border-bottom: 1px solid var(--brown-lite);

    &:focus {
      background-color: $white;
    }
  }

  .form-group {
    margin-bottom: 0;
    padding: 0 15px 0 0;
  }

  .s_date-picker .s_ico-cal {
    background-color: transparent;
  }

  select {
    font-size: 0.8em;
    min-height: 31px;
  }
}

/*=====  End of PROMPT MODAL  ======*/

/*=============================================
=            `MODAL PROMPT            =
=============================================*/
.alert {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #faf5f2;

  .modal-header {
    padding: 0 0 6px;

    .modal-title {
      font-weight: 100;
      font-size: 1.6rem;
    }
  }

  .modal-body {
    padding: 20px 0;
  }

  .modal-footer {
    padding: 10px 0 0;
  }

  .btn {
    &.s_btn-confirmation-ok,
    &.s_btn-confirmation-cancel,
    &.s_btn-confirmation-aux1 {
      min-width: 80px;
    }
  }
}

/*=====  End of PROMPT MODAL  ======*/

/*=============================================
=            `DIALOG (GENERIC)           =
=============================================*/
.s_dia {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 10px;
  transition: all 0.15s ease;
}

.s_dia.show::before {
  /*----------  Page-screen prevents non-dialog input  ----------*/
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  cursor: default;
  z-index: 2;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}

/*=====  End of DIALOG  ======*/

/*=============================================
=            `MAIN          =
=============================================*/

main {
  width: 100%;
  padding: 0;
  min-height: 100vh;
}

.s_con-header {
  margin: 10px 0;
}

.s_con-title,
.s_dia-title {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 32px;
}

@include media-breakpoint-down(sm) {
  .s_con {
    padding-right: 0;
    padding-left: 0;
  }

  main {
    min-width: 350px;
  }
}

.s_con-subtitle {
  font-size: 1rem;
  font-weight: 400;
}

.s_selector {
  margin-bottom: 0;
  padding-bottom: 0;
  min-width: 200px;
  border: 0px;
  outline: 0px;
  background-color: transparent;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 400;
}

.s_pipe {
  border-left: 1px $gray-500 solid;
  margin: auto 16px;
  height: 40px;
}

.s_pipe.float-left {
  /*  when adjacent to dropdown (i.e. table header page) */
  padding-left: 5px;
}

@include media-breakpoint-down(sm) {
  .s_con-title {
    padding-top: 0;
    font-size: 1rem;
    font-weight: 600;
  }
}

.s_sys-notice {
  width: 100%;

  .s_con-title {
    padding-left: 16px;
  }

  hr {
    border-top: none;
    border-bottom: 3px double var(--brown);
    padding-top: 14px;
  }
}

@include media-breakpoint-down(md) {
  .s_sys-notice {
    padding-left: 218px;
  }
}

@include media-breakpoint-up(lg) {
  .s_sys-notice {
    padding-left: 109px;
  }
}

/*=====  End of CONTENT  ======*/

/*=============================================
=            `DOCUMENT VIEWER            =
=============================================*/

.s_viewer-title {
  min-width: 250px;
  line-height: 24px;

  .btn {
    border-radius: 5px !important;
    height: 28px;
    margin-top: 2px;
    font-size: 0.7rem;
  }

  .s_doc-action {
    min-width: 65px;
    font-weight: bold;
  }

  .s_doc-action_close {
    min-width: 130px;
  }

  .s_filename,
  .input-group-text {
    display: inline-block;
    margin-top: 2px;
    line-height: 28px;
    height: 28px;
    font-size: 0.8rem;
  }
}

.s_sec .s_viewer-title,
.s_sec .s_viewer-actions {
  .s_ico {
    padding-top: 4px;

    ins::before {
      font-size: 28px;
    }
  }
}

.s_viewer-corresp {
  min-width: 960px;
}

.s_filedrop {
  overflow: hidden;
  margin-right: 85px;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.s_viewer-embed,
.s_viewer-iframe {
  width: 100%;
  height: calc(100vh - 110px);
}

/*=====  End of `DOCUMENT VIEWER  ======*/

/*=============================================
=            `ACCORDION SHOW/HIDERS            =
=============================================*/
/*=====  .s_acc = Accordion: Just Show/Hide Content   ======*/
.collapsing {
  transition: height 0.15s ease;
}

/*=====  .s_twy = Twisty: Shows arrow next to link with an underline   ======*/
.s_twy,
.s_sec {
  padding: 12px 0;

  > a:not(.s_ico-embed),
  > a:not(.s_ico-embed):not(.s_ico),
  > button.s_accordion-link {
    color: var(--brown);
    font-weight: bold;
    font-size: 15px;

    &:hover,
    &:active {
      cursor: pointer;
      color: $black;
    }

    &::before {
      /*=====  Arrow Down (Open)  ======*/
      content: '\e111';
      font-family: 'safdings';
      font-size: 14px;
      line-height: 18px;
      float: left;
      margin-right: 8px;
      font-weight: bold;
    }

    &.collapsed::before {
      /*=====  Arrow Down (Open)  ======*/
      content: '\e111';
      transform: rotate(-90deg);
    }
  }
}

.s_sec {
  @include doublebot;

  // NOTE: :has not supported in Firefox, but this is just a nice to have anyway
  &.s_sec-drop-bottom-border:has(> .collapsed) {
    border-bottom: none;
  }
}

.s_twy {
  border-bottom: 1px var(--brown) solid;

  .s_ico-remove_fill::before,
  .s_ico-remove_fill:hover::before {
    font-size: 18px;
    line-height: 18px;
  }
}

.s_rsz {
  .s_sec {
    padding: 12px 5px;
  }

  .s_twy {
    border-bottom: 1px var(--brown-lite) dotted;
    font-size: 0.9em;
    margin-top: -12px;

    a:not(.s_ico-embed) {
      color: var(--label);
      font-size: 12px;
      font-weight: 600;

      &:hover {
        color: $black;
      }
    }
  }
}

// a.s_sec {
//   padding: 10px 0;
//   margin: 3px 0;
//   color: var(--brown);
//   display: block;
//   overflow: hidden;
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 16px;
//   border-left: 3px transparent solid;
//   &:hover,
//   &:active {
//     cursor: pointer;
//     color: $gold-drk;
//   }
//   &::before {
//     /*=====  Arrow Down (Open)  ======*/
//     content: '\e105';
//     font-family: 'safdings';
//     font-size: 14px;
//     float: left;
//     margin-right: 6px;
//     font-weight: bold;
//   }
//   &.collapsed::before {
//     /*=====  Arrow Down (Open)  ======*/
//     content: '\e105';
//     transform: rotate(-90deg);
//   }
// }
/*----------  ICON - Within Sections - Fill with solid background  ----------*/
.s_sec {
  .btn-sm {
    margin-top: 2px;
  }
}

.s_glass {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: $rds;
}

/*=====  .s_tgl = toggle on/off content   ======*/

.s_tlg-subtwy {
  margin-top: -24px;

  .s_sec .link-button {
    font-size: 0.9em !important;
    font-weight: 500 !important;
    margin-top: -6px;
  }

  .s_sec {
    margin-left: 0;
    margin-right: 0;

    a.link-button::before {
      font-weight: 100 !important;
    }
  }
}

.s_tlg > a:not(.s_filelink)::before {
  color: $white;
  font-family: 'safdings';
  content: '\e105';
  font-weight: 900;
  display: inline-block;
  width: 32px;
  border-radius: 50%;
  text-align: center;
  margin: 0 2px 0 0;
}

.s_tgl-wrap,
.s_tgl-actions {
  padding: 10px;
  line-height: 32px;
}

.s_tgl:first-of-type {
  margin-left: 0;
}

.s_tgl::before {
  line-height: 30px;
  width: 32px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
}

.s_filecontent-title {
  min-width: 80px;
  float: left;
  text-align: left;
}

/*=============================================
=           `SWITCH CONTROL           =
=============================================*/
/*------ YES / NO ---------*/
.s_switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 28px;
  margin-bottom: -2px;
  margin-top: 2px;
}

.s_switch input {
  display: none;
}

.s_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid var(--brown);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.s_slider::after {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 2px;
  background-color: var(--brown);
  -webkit-transition: 0.4s;
  transition: 0.2s;
  border-radius: 50%;
}

input:checked + .s_slider {
  background-color: var(--brown);
}

input:checked + .s_slider::after {
  transform: translateX(26px);
  background: $white;
}

/*------ ADDED Text ---------*/
.on {
  display: none;
}

.on,
.off {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

.on {
  color: $white;
  left: 16px;
}

.off {
  color: var(--brown);
  left: 38px;
}

input:checked + .s_slider .on {
  display: block;
}

input:checked + .s_slider .off {
  display: none;
}

/*------ CUSTOM TEXT TOGGLES ---------*/

/*------ CUSTOM TEXT TOGGLES ---------*/

.s_switch-sent,
.s_switch-lock,
.s_switch-wide,
.s_switch-support {
  .s_slider {
    background-color: transparent;

    &::after {
      background-color: var(--brown);
    }
  }

  width: 105px;

  input:checked + .s_slider {
    &::after {
      transform: translateX(75px);
      background: $white;
    }
  }

  .off {
    left: 60px;
    color: var(--brown);
  }

  .on {
    left: 40px;
    color: $white;
    font-weight: 600;
  }
}

.s_switch-lock input:checked + .s_slider {
  background-color: $danger;
}
.s_switch-sent input:checked + .s_slider {
  background-color: $success;
}

.s_switch-company-logo {
  .s_slider {
    background-color: transparent;

    &::after {
      background-color: var(--brown);
    }
  }

  width: 190px;

  input:checked + .s_slider {
    background-color: $danger;

    &::after {
      transform: translateX(160px);
      background: $white;
    }
  }

  .off {
    left: 80px;
    color: var(--brown);
  }

  .on {
    left: 70px;
    color: $white;
    font-weight: 600;
  }
}

.s_switch-company-logo input:checked + .s_slider {
  background-color: var(--brown);
}
/*=====  End of SWITCH  ======*/

/*=============================================
=            `ACTIONS / BUTTONS            =
=============================================*/
/*=====  Small button with only an icon in it (e.g. action button in docs  ======*/
.s_icon-btn {
  padding: 1px 6px 0;
  border: 0;

  ins {
    position: relative;
    top: 3px;
  }
}

hr.s_section {
  overflow: visible;
  padding: 0;
  border: none;
  border-top: 3px double var(--brown);
  color: var(--brown);
  text-align: center;

  &::after {
    content: '';
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.3em;
    padding: 0 16px;
    background: #fcf6f6;
    color: $gold;
  }
}

.s_act {
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
  margin: 0 0 15px;
  padding: 15px 0;
  border-top: 3px double var(--brown);
  border-bottom: 1px solid var(--brown);
  background: url(../assets/img/s_bg-paper.jpg);
}

.s_act.s_no-stick {
  position: relative;
}

.s_search-mag::before {
  content: url(../assets/img/s_icon-search.png);
  margin-top: 4px;
}

.s_search-clear::after {
  content: '\e099';
  display: block;
  background-color: transparent;
  font: 600 16px safdings;
  line-height: 30px;
  padding-right: 8px;
  margin-left: 8px;
}

.s_filter {
  cursor: default;

  background-image: url(../assets/img/filter-off.svg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 18px;

  .s_filter-clear {
    background-color: $tan;
    border-bottom: 1px solid var(--brown-lite);
  }
  input:active,
  input:focus {
    & ~ .s_filter-clear {
      background-color: white;
      border-bottom: 1px solid var(--brown);
    }
  }
}

/*=====  End of ACTIONS  ======*/
/*=============================================
=            `BUTTONS            =
=============================================*/

@include media-breakpoint-up(md) {
  .s_edit,
  .s_save,
  .s_cancel,
  .s_delete {
    min-width: 80px;
  }
}

.s_cancel {
  margin-left: 4px;
}

.s_delete {
  margin-left: 12px;

  &::before {
    background: darken($gray-400, 10%);
    display: inline-block;
    width: 1px;
    content: '';
    height: 32px;
    margin-left: -1.4rem;
    position: absolute;
    top: 0;
  }
}

.btn.dropdown-toggle {
  min-width: 0%;
}

[class*='btn-'] {
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.2);

  &:focus,
  &:active:focus,
  &.active {
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.2);
  }
}

.btn-outline-warning:not(:disabled):not(.disabled):hover,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  color: $white !important;
}

.btn-light {
  color: $black !important;
}

$buttonArr:
  '-primary' $blue-lite $blue,
  '-secondary' $gray-lite $secondary,
  '-success' $green-lite $green,
  '-danger' $red-lite $red,
  '-warning' $yellow-lite $yellow,
  '-info' var(--brown-lite) var(--brown);

@each $arr in $buttonArr {
  .btn-ghost#{nth($arr, 1)} {
    background: transparent;
    color: nth($arr, 3);
    box-shadow: none;
  }

  .btn-ghost#{nth($arr, 1)}:hover {
    background: nth($arr, 3);
    color: $white;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  }
}

.btn-ghost-secondary {
  -webkit-appearance: unset;
  color: #6c757d;
  background: transparent;
}

@each $arr in $buttonArr {
  .input-group-append .btn-outline#{nth($arr, 1)} {
    color: nth($arr, 3);
    box-shadow: none;
  }

  .input-group-append .btn-outline#{nth($arr, 1)}:hover {
    background: nth($arr, 3);
    color: $white;
  }
}

.s_minibutton {
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
}

.s_mini {
  padding: 4px 6px;
  border: 1px solid $gray-600;
  color: $gray-700;
  font-size: 11px;
  border-radius: 4px;

  &:hover,
  &:active {
    background: var(--primary);
    color: $white;
    border-color: var(--primary);
  }
}

.s_btn-tall {
  line-height: 2.3rem;
}
.s_btn {
  min-width: 80px;
  text-align: center;
}

/*=====  End of BUTTONS  ======*/
/*=============================================
=            `SUMMARY            =
=============================================*/
.s_summary {
  border-radius: 5px;
  padding: 10px;
  font-size: 0.9em;
  background-color: $white;
  min-height: 100px;
}

.s_summary div span {
  display: block;
  padding: 5px 0;
}

.s_summary-label {
  min-width: 75px;
  font-weight: 600;
  float: left;
  color: #ababab;
}

.s_summary-content {
  min-width: 50px;

  &:last-of-type {
    margin-top: 8px;
  }
}

/*=====  End of SUMMARY  ======*/
/*=============================================
=            `SPACING            =
=============================================*/
@include media-breakpoint-down(md) {
  .form-group .s_mb-lg-3 {
    margin-bottom: 1rem;
  }

  .form-group .s_mb-lg-3:last-of-type {
    margin-bottom: 0;
  }
}

/*=====  End of SPACING  ======*/
/*=============================================
=            `FORMS            =
=============================================*/
#file_input_id {
  display: none;
}

form label,
.s_label {
  text-transform: uppercase;
  color: var(--label);
  font-size: 0.8em;
  font-weight: 600;
  display: block;
}

.s_reg {
  text-transform: none;
}

.input-group-text,
.form-control {
  border-radius: 0;
  border: 1px transparent solid;
  border-width: 0 0 1px 0;
}

.form-control {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
    border: 1px var(--brown) solid;
    border-width: 0 0 1px 0;
  }
}

.form-control option:disabled {
  color: $gray-400;
}

.s_req,
.s_sft {
  &::before {
    height: 24px;
    width: 2px;
    position: absolute;
    top: 18px;
    display: block;
    content: '';
    z-index: 4;
  }

  &.s_no-label::before {
    top: 2px;
  }
}

.s_group_validator {
  &.s_req,
  &.s_sft {
    display: inline-block;
    &::before {
      height: 14px;
      top: 0;
    }
  }
}

.s_sft::before {
  background: $warning;
}

.s_req::before {
  background: $red !important;
}

.s_help {
  display: block;
  margin: 2px 0;
  color: $gray-500;
  font-size: 0.9em;
}

.s_passcode {
  max-width: 40px;
}

.s_total {
  .input-group-text,
  .form-control {
    background-color: transparent;
    border-color: transparent;
  }
}

/*=====  End of FORMS  ======*/
/*=============================================
=            APPEND            =
=============================================*/
#lpms-document-delivery-setup-delivery-method {
  font-size: 12px;
}

.input-group-text {
  padding: 0 6px;
  background-color: $white;

  &.s_prefix {
    min-width: 80px;
    font-size: 0.9em;
    text-transform: uppercase;
    background: transparent;
    border: none;
    text-align: left;
    color: var(--label);

    &.s_prefix-stack {
      display: table-cell;
      vertical-align: text-top;
      line-height: 12px !important;
      margin-top: 6px;
    }
  }

  &.s_prefix-label {
    line-height: 32px;
  }

  &.s_postfix {
    line-height: 12px;
    font-size: 12px;
    color: var(--label);
    white-space: normal;
    font-size: 0.7rem;
    background: transparent;
  }
}

.input-group-text[class*='s_ico-'] {
  color: var(--brown);
  align-self: flex-start;
  padding: 3px 3px 2px;
}

.s_input-group-no-border {
  border-width: 1px;
}

.input-group-sm {
  .input-group-append,
  .input-group-prepend {
    .input-group-text {
      padding: 2px 2px 1px;
    }
  }
}

/* Need to add a margin to line up with a standard input control */
.s_prefix-currency {
  padding-bottom: 1px;
}

.s_prefix-sub {
  min-width: 85px;
  color: var(--label);
  font-size: 11px;
}

/*=====  End of ADD-ONs  ======*/
/*=============================================
=            TABS            =
=============================================*/
.s_toggle {
  height: 32px;
  border-left: 1px $gray-500 solid;
  font-size: 0.8rem;
  color: var(--brown);
  outline-width: 10px;
  display: inline-block;

  span {
    margin: 6px 8px 4px;
    display: inline-block;
  }

  &:first-of-type {
    border-left: none;
  }

  &.active,
  &:hover {
    span {
      border-bottom: 1px var(--brown) solid;
    }
  }
}

.s_tab {
  border-bottom: 1px solid black;

  li {
    font-weight: 600;
    font-size: 0.9em;
    margin: 0 4px -1px 0;
    padding: 6px 12px;
    text-align: center;
    border: 1px solid var(--brown);
    display: inline-block;
    border-radius: 5px 5px 0 0;
    background-color: $bg;

    &:first-of-type {
      margin-left: 8px;
    }

    a {
      color: black;
    }

    &:hover {
      background: $tope-lite;

      a {
        color: var(--brown-drk);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .s_tab li {
    margin-right: 8px;

    a {
      min-width: 100px;
      display: inline-block;
    }
  }
}

.s_tab li.active,
.s_tab li.active:hover {
  background-color: $tan;
  border-bottom-color: $tan;

  a {
    color: var(--brown-drk);
  }
}

/*=====  End of TABS  ======*/

/*=============================================
=            TIMELINE            =
=============================================*/
.progbar .progbar-item {
  border: 1px solid #c2aea3;
  border-width: 1px 0;
  padding: 4px 12px 4px 28px;
  background-color: rgba(100, 85, 79, 0.06);
  font-weight: 600;
  font-weight: 600;
  font-size: 0.9em;
  line-height: 19px;
  text-align: center;
  color: $gray-500;
  margin: 4px 0;
  max-width: 190px;

  &::after {
    background-image: url(../assets/img/arw0.png);
    background-position: center left;
    background-size: cover;
    background-repeat: no-repeat;
    content: '';
    width: 32px;
    height: 100%;
    display: inline-block;
    position: absolute;
    right: -32px;
    top: 0;
    z-index: 1;
  }

  &:nth-of-type(1) {
    padding-left: 12px;
  }

  &:last-of-type::after,
  &:nth-of-type(5)::after {
    display: none;
  }

  ins {
    &::before {
      line-height: 20px;
      margin-left: 8px;
      position: absolute;
      font-size: 18px;
    }
  }

  small {
    text-transform: uppercase;
  }
}

$bg:
  '1' 'hsl(0deg 0% 95%)' '#e3d9d3' black,
  '2' 'hsl(124deg 60% 92%)' 'hsl(124deg 50% 86%)' black,
  '3' 'hsl(124deg 50% 86%)' 'hsl(124deg 40% 78%)' black,
  '4' 'hsl(124deg 40% 78%)' 'hsl(124deg 35% 70%)' black,
  '5' 'hsl(124deg 35% 70%)' 'hsl(128deg 35% 60%)' black;

@each $nth, $arw, $lum, $txt in $bg {
  .progbar .progbar-item.s_grn:nth-of-type(#{$nth}) {
    background-color: #{$lum};
    color: $txt;

    &::after {
      background-image: url(../assets/img/arw#{$nth}.png);
    }

    &:nth-of-type(5)::after {
      display: none;
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: -12px;
    //   top: 0;
    //   width: 0;
    //   height: 0;
    //   border-top: 13px solid #{$lum};
    //   border-bottom: 13px solid #{$lum};
    //   border-left: 13px solid #{$arw};
    // }
  }

  .progbar .progbar-item.s_grn.s_dlv {
    background: linear-gradient(270deg, #ece9ea 40%, #98cd9b 100%);

    &::after {
      background-image: url(../assets/img/arw0.png);
    }
  }
  .progbar .progbar-item.s_red {
    background: #dc4646;
    color: $white;
  }
}

/*=====  End of TIMELINE  ======*/

/*=============================================
=            FlexMonster            =
=============================================*/
#fm-toolbar-wrapper #fm-toolbar {
  background: $paper;
}

/*=====  End of Flex Monster  ======*/

/*=============================================
=  `FORM: CONTROLS (i.e. select, checkbox, radio) =
=============================================*/
select,
select.form-control,
.bootstrap-select > .btn {
  -webkit-appearance: none;
  /*Removes default chr ome and safari style*/
  -moz-appearance: none;
  /* Removes Default Firefox style*/
  background-repeat: no-repeat;
  background-position: right center;
  /*Position of the background-image*/
  text-indent: 0.01px;
  /* Removes default arrow from firefox*/
  text-overflow: '';
  /*Removes default arrow from firefox*/
  padding-top: 0 !important;
  padding-right: 45px !important;
  padding-bottom: 0 !important;
  padding-left: 8px;
  vertical-align: middle;
  line-height: normal;
}

select.form-control,
.bootstrap-select > .btn {
  /* gray arrow within dropdown */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAfCAYAAABgfwTIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkVGRTNCQkRFNkRGNDExRUE4RDcyOEFDNjIxRDVFOTdCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkVGRTNCQkRGNkRGNDExRUE4RDcyOEFDNjIxRDVFOTdCIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RUZFM0JCREM2REY0MTFFQThENzI4QUM2MjFENUU5N0IiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RUZFM0JCREQ2REY0MTFFQThENzI4QUM2MjFENUU5N0IiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4SOjRUAAABOklEQVR42mL8//8/w2ADTAyDEIw6imhH3bhx5/9oSI06ioqAhRhFdV3TqG5xU1nW8I6+QiCeTYF9s6FmUNVRIkCcAsS5ZDgoD6pXhNqOqgPiXUDcA8Q2JOgDqe0G4p1QM6jqqL9AHAXEz4B4FRBLEaFHCqr2KRBHQ82gepHwFoiDgJgfahkbHrVsUDUgtcFQvTQrp84DcTYQWwNxLx51fVA1WVA91CuncIAFQGwCxDlAfAaIF6LJJ0AdPgWLHE1L9CIgPgrEoNLVEEkcxJ4KlSumdzXzC4jDgPgjEK8FYmEoXgcVC4OqoX41QwA8g1q+F4iXATEjNMc5Q+UYBsJRIHAEiEuBeCJSQXmEphUykWASEEdC2ZNp3kogAVgO2/YUw/Xrt/+D+n6DCY82h4kFjKPd9lFHURkABBgAG1CpDrpeVN8AAAAASUVORK5CYII=');
  cursor: pointer;
}

select[disabled] {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAfCAYAAABgfwTIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjFBQkY4MDc3NkRGNTExRUE5RTM5QzEzREFCNDI1NTFEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjFBQkY4MDc4NkRGNTExRUE5RTM5QzEzREFCNDI1NTFEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MUFCRjgwNzU2REY1MTFFQTlFMzlDMTNEQUI0MjU1MUQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MUFCRjgwNzY2REY1MTFFQTlFMzlDMTNEQUI0MjU1MUQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5n5j+tAAABM0lEQVR42mL8//8/w2ADTAyDEIw6imhH3bhx5/9oSI06ioqAhRhFdV3TqG5xU1nW8I4+SyD2o8A+P6gZVHUUFxAbAbE5GQ4yh+rloraj9gPxXSB2A2I5EvTJQfXcgZpBVUf9A+K1QPwZiEOBmJcIPbxQtZ+AeB3UDKoXCd+AeCUQc0AtY8ajlhmqBqR2FVQvzcqp50C8FRot7njUuUPVbIXqoV45hQNcAGIpIDYD4mdQPjIwgMqdwiJH0xJ9JxA/AmJvIJZEEpeEij2CqqFrNfMXiFcD8Q8gDoNmdxAOh4qthqqhe933GWo5HxAHA3EINMethsoNWIUMiqZdQOwCxM5Q9iOaVshEgpNAbI/EZhgMjgKBrmHbnmK4fv32f1DfbzDh0eYwsYBxtNs+6igqA4AAAwB3e6jFN2vijQAAAABJRU5ErkJggg==');
  /*Adds background-image*/
}

.bootstrap-select .caret {
  /*overrides small arrow in bootstrap select and replaces it with our standard dropdown arrow*/
  display: none;
}

select::-ms-expand {
  /*Removes arrow from select in IE */
  display: none;
}

select::-ms-value {
  /* IE Fix: Remove coloring on page load or element select */
  background-color: transparent;
  /* From form-control */
  color: #333333;
}

select option:disabled,
select option:disabled:selected {
  color: #808080 !important;
}

optgroup {
  font-weight: 600;
  color: $black;
}

.s_check-stack {
  line-height: 12px;
  margin-top: -4px;
}
.s_check-line {
  font-weight: 600;
  line-height: 18px;
  font-size: 12px;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #b0b5b9;
  border-color: #b0b5b9;
}
.s_check-inline .custom-checkbox {
  min-width: 200px;
}

.s_check-inline .custom-control-label {
  white-space: nowrap;
}

.s_check-inline .custom-control-label .form-control-sm {
  max-width: 160px;
  height: calc(1.5rem + 2px);
}

.s_check-lg {
  zoom: 1.8;
  cursor: pointer;
  transform: scale(1.8);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
}

$checkcolor:
  '-primary' var(--primary),
  '-success' $success,
  '-danger' $danger,
  '-warn' $warning,
  '-warning' #a08b00;

@each $arr in $checkcolor {
  .s_checkcolor#{nth($arr, 1)} .custom-control-input:checked ~ .custom-control-label::before {
    background-color: nth($arr, 2) !important;
  }

  .s_checkcolor#{nth($arr, 1)} {
    .custom-control-label::before {
      border-color: nth($arr, 2) !important;
    }

    & label {
      color: nth($arr, 2);
      font-size: 0.9rem;
      line-height: 14px;
      font-weight: 600;

      small {
        line-height: 12px;
        text-align: left;
      }
    }
  }
}

.s_check-label {
  min-width: 142px;
}

/*=====  End of FORM: SELECT  ======*/
/*=============================================
=            PAYMENT            =
=============================================*/
.s_payment {
  padding-bottom: 12px;

  .form-group {
    &.col-md-3 {
      padding-right: 0;
      margin-bottom: 0;
    }
  }
}

/*=====  End of PAYMENT  ======*/
/*=============================================
=            BADGES            =
=============================================*/

.s_bdg {
  float: right;
  min-width: 30px;
  text-align: center;

  &::before {
    font-size: 16px !important;
  }
}

/*=====  End of BADGES  ======*/
/*=============================================
=            INVOICE            =
=============================================*/

.s_inv-header {
  background-color: $gray-200;
  margin-top: -15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px $gray-500 solid;
}

.s_inv-title {
  font-size: 1.5rem;
  color: $gray-600;
}

.s_inv-total {
  border-top: 2px $gray-300 solid;
  margin-top: -5px;
  padding-top: 10px;
}

.s_inv-gray {
  background-color: $gray-100;
  border-radius: $rds;
}

.s_inv hr {
  margin: 5px 0;
}

.s_inv .form-group {
  margin-bottom: 0;
}

.s_invoice-edit-preview.modal-dialog {
  max-width: 780px;
  .modal-dialog {
    width: 760px;
    height: 700px;
    max-width: 760px;
  }
}

/*=====  End of INVOICE  ======*/
/*=============================================
=            READ TEXT            =
=============================================*/

.s_read {
  padding: 0;
}

.s_read.s_field {
  display: table-cell;
  vertical-align: middle;
  padding: 0.25rem 0.5rem;
  border: 1px solid transparent;
}

.s_num {
  min-width: 35px;
  display: inline-block;
}

.s_qst {
  color: $gray-500;
}

.s_rsp {
  color: $black;
}

.s_lower {
  text-transform: lowercase;
}

/*=====  End of READ TEXT  ======*/
/*=============================================
=            `CHECKLIST            =
=============================================*/

.s_chk {
  .custom-control-input ~ .custom-control-label::before,
  .custom-control-input:active ~ .custom-control-label::before,
  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: var(--brown);
  }

  .dropdown-toggle {
    .custom-control-input:not(:checked) ~ .custom-control-label::after {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMzJweCINCgkgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnIGlkPSJMYXllcl8xIj4NCjwvZz4NCjxnIGlkPSJMYXllcl8yIj4NCgk8Zz4NCgkJPHBhdGggZmlsbD0iIzQ5MjkwRiIgZD0iTTguNDcyLDE5LjU1NmMtMS42MzksMC0yLjk3MiwxLjMzMy0yLjk3MiwyLjk3MlM2LjgzMywyNS41LDguNDcyLDI1LjVzMi45NzItMS4zMzMsMi45NzItMi45NzINCgkJCVMxMC4xMTEsMTkuNTU2LDguNDcyLDE5LjU1NnogTTguNDcyLDI0LjVjLTEuMDg3LDAtMS45NzItMC44ODUtMS45NzItMS45NzJzMC44ODUtMS45NzIsMS45NzItMS45NzJzMS45NzIsMC44ODUsMS45NzIsMS45NzINCgkJCVM5LjU2LDI0LjUsOC40NzIsMjQuNXoiLz4NCgkJPHBhdGggZmlsbD0iIzQ5MjkwRiIgZD0iTTE2LDE5LjU1NmMtMS42MzksMC0yLjk3MiwxLjMzMy0yLjk3MiwyLjk3MlMxNC4zNjEsMjUuNSwxNiwyNS41czIuOTcyLTEuMzMzLDIuOTcyLTIuOTcyDQoJCQlTMTcuNjM5LDE5LjU1NiwxNiwxOS41NTZ6IE0xNiwyNC41Yy0xLjA4NywwLTEuOTcyLTAuODg1LTEuOTcyLTEuOTcyczAuODg1LTEuOTcyLDEuOTcyLTEuOTcyczEuOTcyLDAuODg1LDEuOTcyLDEuOTcyDQoJCQlTMTcuMDg3LDI0LjUsMTYsMjQuNXoiLz4NCgkJPHBhdGggZmlsbD0iIzQ5MjkwRiIgZD0iTTIzLjUyOCwxOS41NTZjLTEuNjM5LDAtMi45NzIsMS4zMzMtMi45NzIsMi45NzJzMS4zMzMsMi45NzIsMi45NzIsMi45NzJzMi45NzItMS4zMzMsMi45NzItMi45NzINCgkJCVMyNS4xNjcsMTkuNTU2LDIzLjUyOCwxOS41NTZ6IE0yMy41MjgsMjQuNWMtMS4wODcsMC0xLjk3Mi0wLjg4NS0xLjk3Mi0xLjk3MnMwLjg4NS0xLjk3MiwxLjk3Mi0xLjk3MlMyNS41LDIxLjQ0LDI1LjUsMjIuNTI4DQoJCQlTMjQuNjE1LDI0LjUsMjMuNTI4LDI0LjV6Ii8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=');
      background-size: 110%;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMzJweCINCgkgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnIGlkPSJMYXllcl8xIj4NCjwvZz4NCjxnIGlkPSJMYXllcl8yIj4NCgk8cG9seWdvbiBmaWxsPSIjRkZGRkZGIiBwb2ludHM9IjE0LjMwNiwxMi4yNDIgMTEuNDAzLDkuMzM5IDguNSwxMi4yNDIgMTQuMzA2LDE4LjA0OCAxNC4zNzYsMTcuOTc5IDIzLjUsOC44NTUgMjAuNTk3LDUuOTUyIAkiLz4NCgk8Y2lyY2xlIGZpbGw9IiNGRkZGRkYiIGN4PSI4LjQ3MiIgY3k9IjIyLjUyOCIgcj0iMi40NzIiLz4NCgk8Y2lyY2xlIGZpbGw9IiNGRkZGRkYiIGN4PSIxNiIgY3k9IjIyLjUyOCIgcj0iMi40NzIiLz4NCgk8Y2lyY2xlIGZpbGw9IiNGRkZGRkYiIGN4PSIyMy41MjgiIGN5PSIyMi41MjgiIHI9IjIuNDcyIi8+DQo8L2c+DQo8L3N2Zz4NCg==');
      background-size: 110%;
    }

    ~ .dropdown-menu {
      font-size: 0.8rem;
      padding: 0;
      margin-top: -1px;
      border-color: var(--brown);
      border-radius: 2px;
      box-shadow: 0 10px 15px -15px rgba(0, 0, 0, 0.3);

      .dropdown-item {
        padding: 6px 4px;

        &:hover {
          background-color: $tope;
        }
      }
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--brown);
  }

  .s_chk-label {
    .col {
      margin-bottom: 0;

      .custom-control-label {
        font-weight: 600;
      }
    }

    .s_ico-embed {
      ins {
        line-height: 28px;
        width: 26px;
        margin: 0 8px 0 0;

        &::before {
          font-size: 32px;
        }
      }
    }
  }

  .row.s_chk-meta {
    border-bottom: 1px solid $gray-400;
    margin-bottom: 4px;
    padding-bottom: 4px;

    .form-group {
      font-size: 0.7rem;
      margin-bottom: 4px;
      color: $gray-500;
    }
  }

  .s_chk-comment {
    padding: 0;

    .form-group {
      padding: 0;
      margin-bottom: 0;

      .input-group-btn .btn,
      .form-control {
        line-height: 1;
        font-size: 0.8rem;
      }
    }

    .list-group-item {
      font-size: 0.8rem;
      padding: 6px 4px;

      div {
        font-weight: 500;
        color: $black;
      }

      .s_chk-meta {
        font-size: 0.7rem;
        color: $gray-500;
      }
    }
  }
}

.s_chk-config {
  .s_picklist {
    margin-top: -14px;
    padding: 0 59px 0 46px;
    border-top-width: 0;

    .input-group-text.s_postfix {
      line-height: 24px;
    }
  }

  .input-group-text {
    padding: auto 0;

    &.s_postfix {
      line-height: 30px;
      font-size: 12px;
      color: var(--label);
      white-space: normal;
      font-size: 0.7rem;
      align-items: start;
      font-weight: 600;
    }
  }
}

/*=====  End of `CHECKLIST  ======*/
/*=============================================
=            CUSTOM FIELD CONTROLS            =
=============================================*/
.custom-control {
  margin-top: 4px;
  margin-bottom: 4px;

  &.s_confirmed {
    .custom-control-input {
      &:checked ~ .custom-control-label {
        &::after,
        &::before {
          background-color: $green;
        }
      }
    }
  }
}

.input-group-text .custom-control {
  margin-bottom: 0;
}

.custom-control-label {
  text-transform: none;
  font-weight: 400;
  color: $gray-800;
  display: inline-block;
  cursor: pointer;
  min-height: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: $white;

  &::after {
    color: $text-muted;
    border-color: $text-muted;
  }
}

.custom-file-label {
  border: 1px solid $white;
  text-transform: none;
  font-weight: 400;
  color: $gray-800;

  &::after {
    color: var(--primary);
    height: auto;
    border: 1px solid var(--primary);
    text-transform: uppercase;
    font-weight: 600;
  }
}

.custom-control-label {
  &::after,
  &::before {
    left: -2rem;
    top: 0;
  }
}

.custom-control-label.custom-control-label-sm {
  &::before,
  &::after {
    height: 1.2rem;
    width: 1.2rem;
  }
}

.custom-checkbox-xs {
  padding-left: 1.5rem;
  min-width: 94px;

  .custom-control-label {
    min-height: 16px;
    text-transform: uppercase;
    color: $gray-600;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.2px;

    &::before,
    &::after {
      height: 16px;
      width: 16px;
      left: -1.5rem;
      margin: 3px 0 0 5px;
    }
  }
}

.custom-checkbox-sm {
  padding-left: 0.75rem;

  .custom-control-label.checkbox-inline {
    min-height: 22px;
    text-transform: uppercase;
    color: $gray-600;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.2px;

    &::before,
    &::after {
      height: 20px;
      width: 20px;
      left: -0.75rem;
      margin: 4px 0 0 2px;
    }
  }
}

.custom-switch .custom-control-label::after {
  top: 0.1rem;
}

.s_bdr-0 {
  .input-group,
  .input-group-text,
  .form-control {
    border-top: 0;
  }
}

/*=====  End of CUSTOM FIELD  ======*/

/*=============================================
=            `MULTIFILE            =
=============================================*/

.s_multifile {
  @include truncate;
  width: calc(100% - 70px);
  display: inline-block;
}

.s_multifile .s_multifile {
  color: $gray-500;
}

.s_loc {
  font-weight: 600;
  margin-left: 10px;
  text-transform: none;
  display: inline-block;

  &.active {
    border-bottom: 2px $blue solid;
  }
}

/*=====  End of MULTIFILE  ======*/
/*=============================================
=            `PAGING            =
=============================================*/

.page-link {
  background-color: transparent;
}

/*=====  End of `PAGING  ======*/
/*=============================================
=            `ICONS            =
=============================================*/
button[class*='s_ico-']:not(.s_ico-no-transparent) {
  background: transparent;
}

[class*='s_ico-'] {
  &:hover {
    cursor: pointer;
  }

  border: none;

  &::before,
  &::after {
    font: 100 24px/24px 'safdings';
  }
}

.s_ico-bold::before,
.s_ico-bold::after {
  font-weight: bold;
}

/*----------  ICON - Array of all icons  ----------*/
$ico-array: $icons;

@each $ico-id in $ico-array {
  .s_ico-#{nth($ico-id, 1)},
  /*----------  Sidebar Icons  ----------*/
  #s_mnu-link-#{nth($ico-id, 1)}>ins {
    &::before {
      content: nth($ico-id, 2);
    }
  }

  .s_ico-embed:not(.collapsed) .s_ico-#{nth($ico-id, 1)},
  .s_ico-embed:hover .s_ico-#{nth($ico-id, 1)} {
    &_fill {
      color: $white;
      font-weight: 600;

      &::before {
        content: nth($ico-id, 3);
        color: var(--brown);
        // color: #bc9f6e; text-shadow: -2px -2px 5px rgba(255, 255, 255, 0.5), 1px 1px 1px rgba(0, 0, 0, 0.5);
        //
      }
    }
  }

  .s_ico-#{nth($ico-id, 1)}_fill {
    &::before {
      content: nth($ico-id, 2);
    }

    &:hover::before {
      content: nth($ico-id, 3);
    }

    &.disabled,
    &.disabled:hover {
      &::before {
        color: $gray-300 !important;
      }
    }
  }

  .s_over.s_ico-#{nth($ico-id, 1)} {
    &::before {
      content: nth($ico-id, 3);
    }
  }

  .s_ico-embed:not(.collapsed) .s_ico-#{nth($ico-id, 1)},
  .s_ico-embed:hover .s_ico-#{nth($ico-id, 1)} {
    &_fill {
      &::before {
        content: nth($ico-id, 3);
      }
    }
  }

  /*----------  ICON - Toggle (Click solid background on/off)  ----------*/
  .s_ico-toggle input {
    display: none;
  }

  /*----------  ICON - Fill with solid background  ----------*/
  input:checked ~ .s_ico-#{nth($ico-id, 1)}::before {
    content: nth($ico-id, 2);
  }
}

/*----------  ICON - Lock  ----------*/
.s_ico-user,
.s_ico-talk,
.s_ico-link {
  &_fill div {
    line-height: 0;
    padding-top: 12px;
    font-size: 11px;
    cursor: pointer;
  }
}

.s_lpo {
  .s_ico-lock_fill div {
    line-height: 0;
    padding-top: 20px;
  }
}

/*----------  ICON - Embed (Add text inside icon)  ----------*/
.s_ico-embed {
  text-align: center;
  font-size: 0.8rem;
}

.s_ico-embed ins {
  line-height: 30px;
  float: left;
  margin-right: 8px;
  width: 28px;
}

.s_ico-embed ins::before {
  line-height: 32px;
  margin-right: -1.7rem;
  float: left;
  font-size: 28px;
}

.s_sec .s_ico-embed ins::before {
  font-size: 32px;
}

.s_sec .s_ico {
  height: 32px;

  ins::before {
    font-size: 32px;
    color: var(--brown) !important;
  }
}

.s_ico-embed .s_ico-folder,
.s_ico-embed .s_ico-file {
  line-height: 32px;
  display: block;

  &_fill {
    line-height: 32px;
  }
}

/*----------  ICON - Size  ----------*/
.s_ico-md::before,
.s_ico-md::after {
  font-size: 16px;
  line-height: 16px;
}

.s_ico-sm::before,
.s_ico-sm::after {
  font-size: 14px;
  line-height: 14px;
}

.s_ico-xs::before,
.s_ico-xs::after {
  font-size: 12px;
  line-height: 12px;
}

/* This is the paperclip icon in correspondence listing, indicating attachments */
.s_ico-attachment {
  &::before {
    content: '\e505';
    font-weight: bold;
    font-size: 15px;
    position: absolute;
    margin-top: -2px;
  }

  span {
    margin-left: 20px;
  }
}

/*----------  ICON - Customization  ----------*/
.s_ico-expand_fill,
.s_ico-collapse_fill {
  &::before {
    line-height: 16px;
  }
}

.s_ico-garbage {
  &_fill,
  &_fill:hover {
    &::before {
      color: $red !important;
    }
  }

  &_fill.disabled,
  &_fill.disabled:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.disabled > .s_ico-garbage {
  &_fill,
  &_fill:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.s_ico-ungarbage {
  &_fill,
  &_fill:hover {
    &::before {
      color: $orange !important;
    }
  }

  &_fill.disabled,
  &_fill.disabled:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.disabled > .s_ico-garbage {
  &_fill,
  &_fill:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.s_ico-close {
  &_fill,
  &_fill:hover {
    &::before {
      color: $red-brite !important;
      cursor: pointer;
      font-size: 28px;
    }
  }

  &_fill.disabled,
  &_fill.disabled:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.s_ico-remove_fill {
  &::before {
    font-size: 14px;
    font-weight: bold;
    color: var(--brown) !important;
  }

  &:hover {
    &::before {
      color: $red-brite !important;
      cursor: pointer;
    }
  }
}

.disabled > .s_ico-remove {
  &_fill,
  &_fill:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.s_ico-grip_fill {
  cursor: n-resize;
}

/*----------  ICON - Star  ----------*/

.s_ico-star_toggle:hover,
.s_ico-star_toggle:active {
  cursor: pointer;
}

.s_ico-star_toggle::after {
  content: '\e103';
  color: var(--brown);
  font-size: 28px;
  line-height: 30px;
}

.s_lpo-header {
  .s_ico-star_toggle::after {
    line-height: 32px;
    font-size: 28px;
    color: $gold;
  }

  div.s_matter-search .s_encircle {
    border-color: $gold;
    color: $gold;

    margin-right: 16px;
    margin-left: 0px;
  }

  div.s_matter-search:hover {
    .s_encircle {
      background: $gold;
      color: var(--brown-drk);

      .s_ico-mag {
        &::before,
        &::after {
          color: var(--brown-drk);
        }
      }
    }
  }

  .s_matter-search-container {
    margin-right: -14px;

    .s_matter-search-no-results {
      color: black;
    }
  }

  input:checked ~ .s_ico-star_toggle::before {
    font-size: 28px;
    line-height: 32px;
    margin-right: -28px;
  }
}

.s_ico-star_toggle:hover::before,
input:checked ~ .s_ico-star_toggle::before {
  color: $gold;
}

.s_ico-star_toggle:hover::before,
input:checked ~ .s_ico-star_toggle::before {
  content: '\e104';
  font-size: 28px;
  line-height: 30px;
  margin-right: -28px;
}

.s_ico-star_toggle:hover::after,
input:checked ~ .s_ico-star_toggle::after {
  color: $gold;
}

/*----------  ICON - Mini icons, for example used as inline links near alerts  ----------*/
ins.s_ico.s_ico-mini::before {
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
}

/*=====  End of ICONS  ======*/
/*=============================================
=            `PORTAL OPTIONS            =
=============================================*/
.s_unadded-txt {
  color: #a08b00;
  font-weight: 600;
}

.s_portal-sec {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px !important;
  padding-left: 8px !important;

  .s_req::before {
    top: 30px;
  }
}

.s_unadded-bg,
button.s_unadded-bg::before {
  background: #fbf1e0;
}

/*=====  End of ICONS  ======*/
/*=============================================
=            `SIDEBAR            =
=============================================*/
// :host is broken because we turned off style isolation to get some of these styles to apply to the header.
// We should fix that later, but not right before a demo!
//:host {
sl-bootstrap-navbar,
#sidebar {
  background: url(../assets/img/s_bg-sidebar.jpg) var(--brown-mid);
  background-position: top right;
}

#sidebar {
  padding-right: 18px;

  .nav-item {
    font-weight: 600;
  }

  ins {
    font: 100 18px 'safdings';
    float: left;
    margin-right: 6px;
    margin-top: 2px;
  }

  /*=====  Active is when user collapses sidebar to a narrow column (to icons only)  ======*/
  &.active {
    min-width: 60px;
    max-width: 60px;

    .sidebar-header .s_brand-collapsed {
      display: block;
      text-align: center;
      margin-left: -2px;
    }

    .sidebar-header .s_brand-expanded {
      display: none;
    }

    .nav-link {
      .badge {
        margin-right: -4px;
        margin-top: 2px;
      }

      @include media-breakpoint-up(lg) {
        &:hover .badge {
          margin-top: -20px;
          margin-right: 0;
        }
      }
    }

    /*=====  End ACTIVE ======*/
  }

  .sidebar-header .s_brand-collapsed {
    display: none;
  }

  .sidebar-header .s_brand-expanded {
    cursor: pointer;
    width: 170px;
    margin-right: 30px;
    content: url(../assets/img/s_wordmark.svg);
  }

  .sidebar-header .s_brand-collapsed {
    height: 60px;
    cursor: pointer;
    content: url(../assets/img/s_wordmark.svg);
    clip-path: inset(0 128px 0 0);
  }

  .dropdown-toggle,
  .nav-subitem,
  .nav-link {
    padding: 14px 10px;
    display: block;
    color: $gold;
    border-left: 2px transparent solid;
    font-size: 0.9rem;
    height: 54px;
    line-height: 24px;

    &.active,
    &:hover {
      color: $gray-200;
    }

    &[data-toggle='collapse'] {
      position: relative;
    }

    .badge {
      margin-top: 4px;
      min-width: 30px;
    }

    .badge-light {
      background: transparent;
      border: 1px solid $gold;
      color: $gold;
    }

    &:hover .badge,
    &.active .badge {
      border-color: $gray-200;
      color: $white;
    }

    .badge-danger,
    &:hover .badge-danger,
    &.active .badge-danger {
      color: $black;
      border: 1px solid lighten($danger, 10%);
      background: lighten($danger, 10%);
    }
  }

  .nav-subitem {
    background: $black;
    padding-left: 32px;

    &.active {
      border-left: 2px $white solid;
    }
  }

  /*=====  Black arrow above the expended subsection ======*/
  &:not(.active) .dropdown-toggle:not(.collapsed)::before {
    position: absolute;
    left: 15px;
    bottom: 0;
    border-right: 9px solid transparent;
    border-bottom: 9px solid var(--brown-drk);
    border-left: 9px solid transparent;
    content: '';
    margin-top: -9px;
  }

  .s_mnu-header {
    padding: 10px;
    color: var(--brown-lite);
    font-size: 0.7rem;
  }

  .s_mnu-company {
    padding-left: 12px;
  }

  .s_mnu-hr {
    hr {
      margin: 0 10px;
      border-top: 1px $gold-drk solid;
    }

    &:first-of-type {
      hr {
        border-top: 3px double $gold-drk;
      }
    }
  }

  .s_sub-hr {
    background: $black;
    margin: 0px;

    hr {
      margin: 0 10px;
      border-color: var(--brown);
    }
  }
}

@include media-breakpoint-up(lg) {
  #sidebar {
    /* Browser Mode */
    &.active::after {
      width: 220px;
      height: 100vh;
      background-color: $paper;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50px;
      z-index: -1;
    }

    &.active .nav-item,
    &.active .s_mnu-header {
      display: none;
    }

    &.active ul.show {
      display: none;
    }

    &.active > ul > li:not(.s_mnu-hr):hover {
      position: relative;
      z-index: 10000;
      display: block;
      width: 250px;
      text-align: left;
      background: var(--brown-mid);
    }

    &.active ul li:hover a .nav-item {
      display: block;
    }

    &.active > ul > li:hover ul {
      left: 50px;
      position: absolute;
      display: block;
      width: 200px;
    }
  }

  main .navbar #sidebarHide {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  /* Phone Mode */
  #sidebar {
    display: none;

    &.active {
      display: block;
      min-width: 210px;
      max-width: 210px;
      text-align: left;
      height: 100vh;
    }

    &.active .sidebar-header .s_brand-expanded {
      display: block;
    }

    &.active .sidebar-header .s_brand-collapsed {
      display: none;
    }

    #sidebarCollapse span {
      display: none;
    }
  }

  .navbar-toggler {
    float: left;
    position: relative;
    padding: 0px;
    margin-right: 16px;

    &:active,
    &:focus {
      outline: 0;
    }

    span {
      display: block;
      background-color: var(--brown);
      height: 2px;
      width: 28px;
      margin: 6px 0;
      transform: rotate(0deg);
      position: relative;
      left: 0;
      opacity: 1;
    }

    span:nth-child(1),
    span:nth-child(3) {
      transition: transform 0.35s ease-in-out;
    }

    &:not(.collapsed) {
      margin-left: -16px;
      margin-top: -2px;

      span:nth-child(1) {
        position: absolute;
        left: 12px;
        top: 10px;
        transform: rotate(135deg);
        opacity: 0.9;
      }
      span:nth-child(2) {
        height: 12px;
        visibility: hidden;
        background-color: transparent;
      }
      span:nth-child(3) {
        position: absolute;
        left: 12px;
        top: 10px;
        transform: rotate(-135deg);
        opacity: 0.9;
      }
    }
  }
}

/*=====  CKEditor  ======*/
/* Body simply to improve specificity rather than using !important */
body {
  .cke_wysiwyg_div[data-cke-editorplaceholder]::before {
    margin-top: 0;
  }

  .cke_toolgroup {
    margin-bottom: 0;
  }

  .cke_combo {
    margin-bottom: 0;
  }

  .cke_autocomplete_panel {
    width: 300px;
  }

  // Hack to hide the toolbar when shown in inline mode. Not as specific as I would like, but it works for now.
  div.ck.ck-balloon-panel.ck-toolbar-container {
    display: none;
  }

  /* Fix positioning and styles for inline editors so they don't overflow their parent box */
  sl-ui-kit-rich-text-editor {
    &.s_enable_min_height .ck-editor__editable_inline {
      min-height: 400px;
    }

    /* Add a bit of space before the content starts or the text is squished at the top.  Note: This should only be 
       done for the normal text areas, not the Correspondence Subject line (editortype=inline).  I wouldn't set the 
       class names like this but it was being done in the section below that needs to revert this for Inline styles
       so I didn't want to make too many changes at once. */
    .ck.ck-content.ck-editor__editable.ck-editor__editable_inline {
      padding-top: 10px;
    }

    &[editortype='inline'] {
      padding: 0px;

      /* While the last class is all we need to select it, we need all the others as well to increase specificity or the default styling takes over. */
      .ck.ck-content.ck-editor__editable.ck-editor__editable_inline {
        padding-top: 0px;
        height: 30px;
        width: 100%;

        p {
          margin: 0px;
          margin-top: 2px;
        }
      }
    }
  }

  .cke_inner {
    background: transparent;
  }

  .cke_top {
    padding: 0;
  }

  .ck.ck-editor__main {
    p {
      margin-top: 0.6rem;
      margin-bottom: 0;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  //adding some buffer to the top of the ckeditor body text
  .cke_wysiwyg_div {
    padding: 8px;
  }

  span[data-safariuserid] {
    color: var(--brown);
    background-color: transparentize($label, 0.9); // REVISIT - This particular variable won't be themed
    border-radius: 5px;
    padding: 0 3px;
  }

  span[data-safarifieldid] {
    background-color: #fafa9e;
  }

  span[data-safaripreview] {
    span[data-safarifieldid] {
      background-color: $gray-200;
    }

    span[data-safarifieldid='77'],
    span[data-safarifieldid='88'],
    span[data-safarifieldid='176'],
    span[data-safarifieldid='177'] {
      /* This removes the background color for CoLogo during previews. We'll still get the nice outline though. */
      background-color: transparent;
    }
  }

  span[data-safarifieldid='77'],
  span[data-safarifieldid='88'],
  span[data-safarifieldid='176'],
  span[data-safarifieldid='177'] {
    /* This is a workaround for an inlined CoLogo resulting from preview subbing in the image tag as a real inline image, no longer a placeholder */
    background-color: #fafa9e;
    display: inline;
    text-align: center;
    max-height: 100px;
    max-width: 250px;
    vertical-align: unset;

    img {
      max-height: 100px;
      max-width: 250px;
    }
  }

  span[data-safarifieldid='176'],
  span[data-safarifieldid='177'] {
    max-width: 400px;

    img {
      max-width: 400px;
    }
  }

  .cke_widget_wrapper:hover > .cke_widget_element,
  .cke_widget_wrapper:active > .cke_widget_element,
  .cke_widget_wrapper:focus > .cke_widget_element {
    outline: none;
  }

  .cke_widget_wrapper.cke_widget_focused > .cke_widget_element,
  .cke_widget_wrapper .cke_widget_editable.cke_widget_editable_focused {
    outline: 1px solid transparent;
  }

  /* Global instance of CKEditor used for data massaging */
  #cke_rich-text-editor-global {
    display: none;
  }

  .ck-content {
    .mention {
      background: yellow;
      color: blue;
    }

    .ck-link_selected {
      background-color: inherit;
    }
  }
  .ck-evaluation-badge-balloon {
    display: none !important;
  }

  .ck .ck-placeholder:before,
  .ck.ck-placeholder:before {
    color: $gray-500 !important;
  }
}

/*=====  END CKEditor =====*/

/*=====  START ngx-bootstrap typeahead =====*/

.input-group.s_search-mag input,
input.s_serving-party-typeahead {
  border-bottom: 1px solid var(--brown-lite);

  &:focus {
    background-color: $white;
  }
}

input.s_typeahead {
  & ~ typeahead-container {
    // On the Info Request edit page style the typeahead flyout with a large width to make it look more like a typical dropdown.
    // We might replace this typeahead later with a different component, but for now this is a decent approximation without any wrapping elements.
    width: 98%;
  }
  &::placeholder {
    // Make the placeholder look like text or a chosen dropdown item. We don't want to fill in the content here so that when the user clicks
    // the dropdown but hasn't typed anything they can get a complete list of options. If the text was prefilled, typeahead would treat it like an
    // existing search with a full match and the rest of the options would not be shown to the user.
    color: inherit;
    opacity: 1;
  }
  &.s_serving-party-typeahead,
  &.s_matter-search-typeahead {
    &::placeholder {
      color: $gray-500;
    }

    & ~ typeahead-container {
      width: 100%;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
      clip-path: inset(0px -10px -10px -10px);
      padding: 0px;
      z-index: 1001;
      border: none;
      background-color: $white;

      max-height: 496px;

      @include scroller;
      overflow-x: hidden;

      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-corner {
        background-color: $white;
      }
      &::-webkit-scrollbar-track {
        border-top: 1px solid $gray-400;
      }

      .dropdown-item {
        padding: 0px;
        border-top: 1px solid $gray-400;
        min-width: 100%;

        &:hover,
        &:active,
        &:focus,
        &.active {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  &.s_matter-search-typeahead {
    & ~ typeahead-container {
      max-height: 586px;
      width: 450px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

      .s_tnc {
        display: inline-block;
        max-width: 426px;
        width: 426px;

        &.s_tnc-half {
          max-width: 188px;
          width: 188px;
        }

        &.s_tnc-smaller {
          max-width: 190px;
          width: 190px;

          .s_lookup-label {
            min-width: 48px;
            width: 48px;
          }
        }

        &.s_tnc-larger {
          max-width: 236px;
          width: 236px;
        }
      }

      .s_lookup-label {
        text-transform: uppercase;
        display: inline-block;
        min-width: 66px;
        color: $gray-500;
        font-weight: 600;
        font-size: 0.75em;
      }

      .matter-search-filters,
      .matter-search-card-header,
      .dropdown-item {
        font-size: 0.7rem;
        padding-left: 4px;
      }

      .matter-search-card-header {
        border-bottom: solid 2px gray;
      }

      .matter-search-filters {
        padding-bottom: 8px;
      }

      .dropdown-item {
        cursor: pointer;
        line-height: 9px;
      }
    }
  }
}

.s_matter-search-container {
  background-color: $white;
  margin-right: -26px;

  .s_matter-search-input-group {
    background-color: $white;
    width: 400px;
  }

  .s_matter-search-no-results {
    background-color: $white;
    width: 400px;
    padding: 4px;
    text-align: center;
    border-top: solid 2px $gray;
    margin-top: -1px; // Hide the focused input bottom border
    position: absolute; // Sit on top of the header bottom border
    z-index: 10;
  }
}

/*=====  END ngx-bootstrap typeahead =====*/

/*
EXPERIMENTAL FEATURE
But if this proves to work well we should consider doing this instead of [hidden] for certain controls. 
This is done mainly to fix ckeditor blowing up if we try to call functions on it while hidden, 
but it may help some issues with datatables as well - that's another component that absolutely 
hates being hidden/shown by the accordion
*/

.hidden-viewport {
  display: block;
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.link-button {
  @extend .btn;
  @extend .btn-link;
  @extend .align-baseline;
  display: inline;
  padding: 0px;
  margin: 0px;
}

.toast-primary {
  background-color: var(--brown);
}

/*=====  MultiSelect Generic Styling  =====*/
.s_multiselect-dropdown {
  width: 100%;
}

.multiselect-item-checkbox {
  input[type='checkbox'] {
    /* Style label, disabled */
    & + div.disabled {
      color: $gray-400;
    }

    /* Style checkbox, checked or unchecked */
    & + div:before {
      color: var(--brown) !important;
      border-color: var(--brown) !important;
    }

    /* Style checkbox, checked */
    &:checked + div:before {
      background-color: var(--brown) !important;
    }
  }
}

.multiselect-dropdown {
  .dropdown-btn {
    /* BEGIN Ripped from library styles */
    display: inline-block;
    border: 1px solid #adadad;
    width: 100%;
    padding: 0 32px 4px 4px !important;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
    /* END   Ripped from library styles */

    /* gray arrow within dropdown */
    background-repeat: no-repeat !important;
    background-position: right top !important;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAfCAYAAABgfwTIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkVGRTNCQkRFNkRGNDExRUE4RDcyOEFDNjIxRDVFOTdCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkVGRTNCQkRGNkRGNDExRUE4RDcyOEFDNjIxRDVFOTdCIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RUZFM0JCREM2REY0MTFFQThENzI4QUM2MjFENUU5N0IiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RUZFM0JCREQ2REY0MTFFQThENzI4QUM2MjFENUU5N0IiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4SOjRUAAABOklEQVR42mL8//8/w2ADTAyDEIw6imhH3bhx5/9oSI06ioqAhRhFdV3TqG5xU1nW8I6+QiCeTYF9s6FmUNVRIkCcAsS5ZDgoD6pXhNqOqgPiXUDcA8Q2JOgDqe0G4p1QM6jqqL9AHAXEz4B4FRBLEaFHCqr2KRBHQ82gepHwFoiDgJgfahkbHrVsUDUgtcFQvTQrp84DcTYQWwNxLx51fVA1WVA91CuncIAFQGwCxDlAfAaIF6LJJ0AdPgWLHE1L9CIgPgrEoNLVEEkcxJ4KlSumdzXzC4jDgPgjEK8FYmEoXgcVC4OqoX41QwA8g1q+F4iXATEjNMc5Q+UYBsJRIHAEiEuBeCJSQXmEphUykWASEEdC2ZNp3kogAVgO2/YUw/Xrt/+D+n6DCY82h4kFjKPd9lFHURkABBgAG1CpDrpeVN8AAAAASUVORK5CYII=') !important;
    cursor: pointer;

    & > span:not(.selected-item-container) {
      // This is the "None Selected" message
      margin-top: 4px;
      display: inline-block;
    }
    .selected-item {
      margin-top: 4px;
      max-width: none !important;
      border-color: var(--brown) !important;
      background-color: var(--brown) !important;

      /* BEGIN Ripped from library styles */
      border: 1px solid #337ab7;
      margin-right: 4px;
      background: #337ab7;
      padding: 0 5px;
      color: #fff;
      border-radius: 2px;
      float: left;
      display: flex;
      //  max-width: 100px;
      /* END   Ripped from library styles */

      a {
        padding-left: 5px !important;
        color: #ff8080 !important;
      }
    }

    .dropdown-multiselect__caret {
      display: none !important;
    }
  }

  .dropdown-list ul {
    /* fix dropdown from pushing page content lower than the body */
    position: relative;
  }

  .no-data,
  .no-filtered-data {
    margin-top: -6px;

    // This 3rd party tool forces an h5 tag for these for whatever reason. We'll just make it behave like a regular span.
    h5 {
      display: inline;
      font-size: inherit;
      color: $gray-500;
    }
  }
}

/*=====  Subtype Access  =====*/
.s_logo-label {
  max-width: 254px;

  .s_ico {
    margin-top: -8px;
    float: right;
    color: var(--brown);

    &::before {
      font-size: 1rem;
    }
  }
}

.s_logo-box {
  width: 250px;
  height: 100px;
  box-sizing: content-box;
  border: 1px $gray-500 dashed;
  display: inline-block;
  text-align: center;

  /* Fix to vertically align the image */
  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  img {
    max-height: 100px;
    max-width: 250px;
    margin: auto;
  }
}

/*=============================================
=            `ATTACHMENT           =
=============================================*/
.s_attach {
  .s_attach-wrap {
    color: $blue;
    border: 1px solid #928b92;
    float: left;
    padding: 1px 4px;
    font-size: 0.8rem;
    max-width: 200px;
    border-radius: 3px;
    margin-right: 8px;
    margin-top: 1px;

    &:hover {
      background-color: lighten($blue, 52%);
      cursor: pointer;
    }

    .s_ico-remove_fill {
      height: 18px;
      min-width: 20px;
      margin: 1px 0 0 8px;
    }
  }

  .s_attach-file {
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 157px;
    line-height: 11px;
    font-size: 0.9em;
    padding-top: 1px;

    div {
      color: $gray-500;
      font-size: 0.7rem;
      padding-top: 1px;
    }
  }

  .s_ico-remove_fill {
    height: 18px;
    min-width: 20px;
    margin-left: 8px;

    &::before {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .btn-outline-primary {
    margin-right: 12px;
    float: left;
  }
}

#s_clipboard-hotspot:hover {
  outline: 1px transparent solid;

  &:hover {
    outline: 1px var(--brown) solid;
  }
}

/*=====  End of ATTACHMENT  ======*/

/*=====  LINKS LIST  ======*/
.s_links-list {
  min-width: 705px;

  .custom-checkbox {
    label {
      line-height: 20px;
    }
  }

  .s_saveto {
    margin-top: 2px;
    float: left;
  }

  .s_links-dupe-alert {
    display: block;
    float: right;
    margin-right: 44px;
    padding-left: 0px;

    .s_alr {
      line-height: 16px;

      & > div {
        margin-right: 0px;
      }

      .s_ico.s_ico-info::before {
        color: $danger;
      }
    }
  }

  .s_link-remove {
    float: right;
    position: absolute;
    right: 0;

    .s_ico-garbage_fill::before {
      line-height: 32px;
    }

    .s_ico-ungarbage_fill::before {
      line-height: 32px;
    }
  }

  // Shrink the possible duplicate alert message and icon
  .s_alr {
    font-size: 0.7rem;

    div,
    span {
      ins::before {
        font-size: 12px;
      }
    }
  }
}

/*=====  End of LINKS LIST  ======*/

/*=====  Start of PARTICIPANTS LIST  ======*/
.s_participants-typeahead ~ typeahead-container {
  width: 100%;

  max-height: 400px;
  overflow-y: scroll;
  @include scroller;

  &::-webkit-scrollbar {
    background-color: white;
  }
  div.row.dropdown-item {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.s_participants-list {
  .s_ico-talk_fill::before {
    position: absolute;
    top: 8px;
  }

  .s_status-or-check {
    padding-top: 0px;
  }
}
/*=====  End of PARTICIPANTS LIST  ======*/

/*=====  Start of `SUBJECTS EDIT  ======*/
.s_matter-edit-subjects {
  .s_dupe-alert {
    float: right;
    margin-right: 12px;
    font-size: 0.7rem;
    line-height: 20px;

    color: $danger;
    font-weight: 600;

    text-transform: none;

    ins {
      // The little (i) alert icon
      display: inline-block;
      translate: 0 1px;
      &::before {
        font: 12px/16px 'safdings';
        margin-left: 4px;
      }
    }
  }
}
/*=====  End of `SUBJECTS EDIT  ======*/

/*=====  START of CHECKBOX COLUMN "BADGES"  ======*/
sl-ui-kit-datatable {
  .s_status {
    display: inline-block;
    position: absolute;
    line-height: 20px;
    font-size: 0.8em;
    top: 5px;
  }
  .s_status-or-check {
    .custom-checkbox {
      opacity: 0;
      &.s_checked {
        opacity: 1;
      }
    }

    &:hover {
      .custom-checkbox {
        opacity: 1;
      }
    }
  }
}
/*=====  END of CHECKBOX COLUMN "BADGES"  ======*/

.s_matter-type {
  color: $gray-600;
}

.s_info,
.s_info:hover {
  &::before,
  &::after {
    color: var(--brown) !important;
  }
}

/*=====  HIDDEN STYLE  ======*/
/* This is used when we need a hidden input control to hold a form value.  
   We're using widht:0; height:0 instead of using hidden input or display:none, 
   because scrollIntoView function will not work on hidden elements *
   
   TODO: 7062
   
   */
input.hidden-scrollable {
  width: 0px;
  height: 0px;
  border: none;
  max-width: 0px;
  padding: 0px;
}

/*=====  End of HIDDEN STYLE ======*/

/*=====  Subtype Picklist  ======*/
.subtypePicklistPrefix {
  color: var(--brown);
  font-size: smaller;
}

.s_change-subtype-wrap {
  padding-top: 4px;
  padding-bottom: 10px;

  .dropdown-toggle {
    margin-top: 1px;
    padding: 0 6px;
    font-size: 0.7rem;
    height: 26px;
    border-radius: 0.2rem;
  }
}

/*=====  End of Subtype Picklist ======*/
/*=====  Matter "Quick Dates"  ============*/
.s_quick-date-dropdown.dropdown-menu {
  @include scroller;

  padding: 0px;
  width: 300px;

  .dropdown-item {
    white-space: normal;
    padding: 4px 8px;
  }

  .s_divider {
    border-bottom: 1px $gray-400 solid;
  }
}

/*=====  End of Matter "Quick Dates" ======*/

/*=====  Serving Party Lookup  ============*/

.s_serving-party-searchbox {
  background-color: $gray-200;
  position: absolute;
  top: -24px;
  left: 0px;
  width: 100%;
  z-index: 1001;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

  .s_serving-party-typeahead {
    border-bottom: none;
    padding: 4px;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
      width: 0;
      height: 0;
    }
    &::-ms-reveal,
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    & ~ typeahead-container {
      margin-top: 0px;
    }
  }

  .s_serving-party-no-results {
    margin: 0px;
    padding: 8px;
    width: 100%;
    border: none;
    border-top: 1px solid $gray-400;
    font-size: 0.9em;
    color: $gray-600;
    background-color: $white;
  }
}

.s_accordion-wrap .s_search-mag::before {
  margin-top: 0px;
}

.s_serving-party-search {
  button {
    height: 30px; // Not sure why this is needed, but it's 1px too big otherwise.
  }

  .s_ico-mag,
  .s_ico-magclose,
  .s_ico-remove {
    &::before {
      font-size: 16px;
    }
  }
}

.s_serving-party-lookup,
input.s_serving-party-typeahead ~ typeahead-container {
  .s_serving-party-lookup-label {
    display: inline-block;
    min-width: 60px;
    color: $gray-500;
    font-weight: 600;

    &.s_serving-party-lookup-phone:not(:first-of-type) {
      min-width: initial; // Shrink label size for labels that aren't the first one to make them fit better
      padding-right: 8px;
    }
  }
  .s_serving-party-lookup-value.s_serving-party-lookup-phone:not(:last-of-type) {
    margin-right: 28px; // Spacing between phone elements
  }
  .s_serving-party-lookup-form-group:not(:last-of-type) {
    // margin-bottom: 4px;
  }
  .s_serving-party-lookup-select {
    padding-top: 8px;
    margin-right: 8px;

    &::before {
      padding-right: 6px;
    }
  }
}

/*=====  End of Serving Party Lookup ======*/

/* REVISIT: These really should be applied by passing CSS classes through, but doing this as a shortcut for now */
#lpms-document-subject-search-subject-search-accordion-content,
#lpms-document-document-ledger-accordion-content {
  padding-top: 0px;
}

/*========= `Maintenance Ribbon ===========*/
div.maintenance-header {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
  margin-bottom: -1px; // This hides the bottom border in matter edit, because it looks weird otherwise
}

/*======= End `Maintenance Ribbon =========*/

/*=====  Read-Only and Disabled Styles  ======*/

// Slider Yes/No, Unlocked/Locked etc would not be affected by form.disable
// but as long as we keep HTML flow consistent this will do it
input[disabled] + .s_slider {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

input:not([type='checkbox']):not([type='radio']).read-only,
select.read-only,
textarea.read-only,
sl-lpms-matter-date-picker.read-only input,
sl-ui-kit-date-picker.read-only input {
  border: none;
  background: #fff9;
  cursor: default !important;
  outline: none;
  padding: 0px 0px 0px 5px;
  /* default behavior is to set opacity to .5 or something, but that makes it too hard to read. */
  opacity: 1 !important;
}

input:where([type='checkbox'], [type='radio']).read-only {
  border: none;
  cursor: default !important;
  outline: none;
  padding: 0px 0px 0px 5px;

  opacity: 0 !important;
}

input.read-only + .custom-control-label {
  cursor: default !important;
}

/*=====  End of Read-Only and Disabled Styles ======*/

div.s_support-header {
  padding-bottom: 10px;
  width: 100%;
}

img.s_support-header {
  max-width: 360px;
  content: url(../assets/img/s_logo-safari-support.png);
}

.s_accordion-wrap {
  .s_accordion-link {
    display: inline-block;
    text-align: left;
    margin-right: 20px;
  }

  /* Special for "card" accordions that have a delete option, like Garn Payment, Garn Account, Date Picker Config Item */
  .s_accordion-delete {
    position: absolute;
    top: 14px;
    right: 20px;
  }

  .s_ico-mag,
  .s_search-clear {
    cursor: pointer;

    &::before,
    ::after {
      font-size: 16px;
    }
  }

  // To align the search open icon with the search close icon
  .s_search-clear {
    padding-right: 0px;
    &::after {
      padding-right: 4px;
    }
  }
  .s_ico-mag {
    padding-right: 4px;
  }
}
.lookup {
  @include scroller;
  max-height: 240px;
  overflow: auto;
  direction: rtl;

  .row {
    direction: ltr;
  }
}

@include media-breakpoint-up(lg) {
  .s_rsz .s_sec-content .row:last-of-type .form-group {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}

/*=============================================
=            `COMMON PAGES                   =
=============================================*/

.common-error-page {
  div.container,
  div.container-fluid {
    animation: fadein 5s;
  }

  .btn {
    min-width: 154px;
    text-transform: uppercase;
  }

  .s_header1 {
    font-size: 2rem;
    font-weight: normal;
  }
  .s_header2 {
    font-size: 1.5rem;
    font-weight: 300;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

/*=====  End of `COMMON PAGES ======*/

// Display of "minimal threshold amount" in garnishment financials and ledger transfer dialog
.s_minimal-threshold-amount {
  font-size: 12px; // s_txt-12;
  color: $black;
  text-transform: none; // Being inside label causes it to be uppercase
  font-style: italic; // font-italic
  font-weight: 600; // font-weight-bold
  float: right; // float-right

  &.s_some-right-margin {
    margin-right: 32px; // Covers the clipboard icon
  }

  &.s_extra-right-margin {
    margin-right: 64px; // Covers both create account and clipboard icons
  }
}
